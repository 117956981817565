import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import FieldSelect from "elements/fieldSelect";
import AmlHashForm from "aml-hash-form";
// includes
import { api, common, types } from "helpers";
import StatusBar from "elements/statusBar";

function Form() {
  // props
  const { id } = useParams();
  const navigate = useNavigate();
  // state
  const [status, setStatus] = useState("process");
  const [loading, setLoading] = useState(null);
  const [effectField, setEffectField] = useState([]);
  // state
  const [permission] = useState(common.getUserPermission());
  const [finder, setFinder] = useState({
    agentSalesPersonId: true,
    studentSalesPersonId: true,
  });
  // validate
  const formFields = {
    subscriptionAdditionalTax: { validate: ["req"], allow: "float" },
    applicationFeesAdditionalTax: { validate: ["req"], allow: "float" },
    applicationFees: { validate: ["req"], allow: "float" },
    applicationCurrency: { validate: ["req"] },
    courseFeesAdditionalTax: { validate: ["req"], allow: "float" },
    agentSalesPersonId: {
      validate: ["objValReq#Please select the sales person"],
      obj: "value",
    },
    studentSalesPersonId: {
      validate: ["objValReq#Please select the sales person"],
      obj: "value",
    },
  };
  const {
    fields,
    errors,
    handleChange,
    handleSubmit,
    setValue,
    setMultiValue,
  } = AmlHashForm(formFields);

  // effect
  useEffect(() => {
    if (!permission.includes("ser")) {
      navigate("/profile");
    } else {
      getInfo();
    }
  }, []);

  useEffect(() => {
    if (effectField.length > 0) {
      setValue(effectField[0], effectField[1]);
      setFinder((prev) => ({ ...prev, [effectField[0]]: false }));
    }
  }, [effectField]);

  // api
  const getInfo = () => {
    let data = {
      url: "setup",
      method: "GET",
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        let result = res.data;
        setMultiValue({
          subscriptionAdditionalTax: result.subscription.additionalTax,
          applicationFeesAdditionalTax: result.applicationFees.additionalTax,
          applicationFees: result.applicationFees.fees,
          applicationCurrency: common.SetSelect(
            result.applicationFees.currency
          ),
          courseFeesAdditionalTax: result.courseFees.additionalTax,
          agentSalesPersonId: result.crm.agentSalesPersonId,
          studentSalesPersonId: result.crm.studentSalesPersonId,
        });
        setStatus("success");
      } else {
        setStatus("error");
      }
    });
  };

  // handler
  const onSubmit = () => {
    let valid = handleSubmit();
    if (valid) {
      setLoading(true);
      let params = {
        subscription: {
          additionalTax: fields.subscriptionAdditionalTax,
        },
        applicationFees: {
          additionalTax: fields.applicationFeesAdditionalTax,
          fees: fields.applicationFees,
          currency: fields.applicationCurrency.value,
        },
        courseFees: {
          additionalTax: fields.courseFeesAdditionalTax,
        },
        crm: {
          agentSalesPersonId: fields.agentSalesPersonId.value,
          studentSalesPersonId: fields.studentSalesPersonId.value,
        },
      };
      let data = {
        url: "setup",
        method: "PUT",
        body: params,
      };
      api.call(data, (res) => {
        if (res.status === 200) {
          common.notify("S", `Setup updated successfully`);
        } else {
          common.notify("E", "Something went wrong");
        }
        setLoading(false);
      });
    }
  };

  // render
  return (
    <Fragment>
      <div className="title-wrap d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <h1 className="page-title ps-4">Setup</h1>
        </div>
      </div>
      <div className="content-wrap">
        {status !== "success" ? (
          <StatusBar status={status} />
        ) : (
          <div className="container-fluid">
            <h2 className="form-title">Tax & Charges</h2>
            <div className="form-wrap">
              <div className="row">
                <div className="col-md-6 mb-4">
                  <label>Additional Tax (Subscription)</label>
                  <input
                    type="text"
                    name="subscriptionAdditionalTax"
                    placeholder="Enter tax"
                    className="form-control"
                    value={fields.subscriptionAdditionalTax}
                    onChange={handleChange}
                  />
                  <p className="error-txt">
                    {errors.subscriptionAdditionalTax}
                  </p>
                </div>

                <div className="col-md-6 mb-4">
                  <label>Additional Tax (Application Fees)</label>
                  <input
                    type="text"
                    name="applicationFeesAdditionalTax"
                    placeholder="Enter tax"
                    className="form-control"
                    value={fields.applicationFeesAdditionalTax}
                    onChange={handleChange}
                  />
                  <p className="error-txt">
                    {errors.applicationFeesAdditionalTax}
                  </p>
                </div>

                <div className="col-md-6 mb-4">
                  <label>Additional Tax (Courses Fees)</label>
                  <input
                    type="text"
                    name="courseFeesAdditionalTax"
                    placeholder="Enter tax"
                    className="form-control"
                    value={fields.courseFeesAdditionalTax}
                    onChange={handleChange}
                  />
                  <p className="error-txt">{errors.courseFeesAdditionalTax}</p>
                </div>

                <div className="col-md-6 mb-4">
                  <label>Application Fees</label>
                  <div className="d-flex">
                    <div className="col-md-7">
                      <input
                        type="text"
                        name="applicationFees"
                        placeholder="Enter fees"
                        className="form-control select-left"
                        value={fields.applicationFees}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-5">
                      <Select
                        className="custom-select-box"
                        value={fields.applicationCurrency}
                        options={types.paymentCurrency}
                        onChange={(data) => {
                          setMultiValue({
                            applicationCurrency: data,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <p className="error-txt">{errors.applicationFees}</p>
                </div>

                <div className="col-md-6 mb-4">
                  <label>Sales Person (Agent)</label>
                  <FieldSelect
                    api="common_sales_person"
                    query="?offset=0&limit=1000"
                    get="EmployeeName"
                    set="EmployeeId"
                    value={fields.agentSalesPersonId}
                    placeholder="Select the responsible person"
                    render={"yes"}
                    clearable={false}
                    finder={finder.agentSalesPersonId}
                    multi={false}
                    onSelect={(data) => {
                      setEffectField(["agentSalesPersonId", data]);
                    }}
                  />
                  <p className="error-txt">{errors.agentSalesPersonId}</p>
                </div>

                <div className="col-md-6 mb-4">
                  <label>Sales Person (Student)</label>
                  <FieldSelect
                    api="common_sales_person"
                    query="?offset=0&limit=1000"
                    get="EmployeeName"
                    set="EmployeeId"
                    value={fields.studentSalesPersonId}
                    placeholder="Select the responsible person"
                    render={"yes"}
                    clearable={false}
                    finder={finder.studentSalesPersonId}
                    multi={false}
                    onSelect={(data) => {
                      setEffectField(["studentSalesPersonId", data]);
                    }}
                  />
                  <p className="error-txt">{errors.studentSalesPersonId}</p>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                className="btn ed-btn-default ed-btn-save-dt"
                onClick={onSubmit}
                disabled={loading}
              >
                {loading ? "Processing..." : "Save & Update"}
              </button>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
}
export default Form;
