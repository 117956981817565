import AmlHashForm from "aml-hash-form";
import {
  ContentState,
  convertFromHTML,
  convertToRaw,
  EditorState,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Fragment, useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import Modal from "react-modal";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";

// includes
import FieldSelect from "elements/fieldSelect";
import ModalConfirm from "elements/modalConfirm";
import StatusBar from "elements/statusBar";
import { api, common, types } from "helpers";

function Form() {
  // props
  const { id } = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();
  console.log("im form state", state);

  // state
  const [init, setInit] = useState(false);
  const [status, setStatus] = useState(id ? "process" : "success");
  const [submit, setSubmit] = useState(null);
  const [loading, setLoading] = useState(null);
  const [myModal, setMyModal] = useState(null);
  const [confirm, setConfirm] = useState(null);
  const [testReload, setTestReload] = useState(null);
  // fields
  const [exam, setExam] = useState([]);
  const [modules, setModules] = useState([]);
  const [intake, setIntake] = useState([]);
  const [error, setError] = useState({
    intakeYear: "",
  });
  // validate
  const [formFieldsA, setFormFields] = useState({
    name: { validate: ["req#Enter the course name"] },
    university: {
      validate: ["objValReq#Please select the university"],
      obj: "value",
    },
    subject: {
      validate: ["objValReq#Please select the subject"],
      obj: "0.value",
    },
    courseLevel: {
      validate: ["objValReq#Please select course level"],
      obj: "value",
    },
    attendanceType: {
      validate: ["objValReq#Please select the course type"],
      obj: "0.value",
    },
    courseDurationType: {
      validate: ["objValReq#Please select the duration"],
      obj: "value",
    },
    tuitionFee: { validate: ["req#Course fees is required"], allow: "float" },
    cab: { validate: ["req#Cashback amount is required"], allow: "float" },
    agcy: { validate: [], allow: "float" },
    cbCy: {
      validate: ["objValReq#Please select the currency"],
      obj: "value",
    },
    agcyCom: { validate: [], obj: "value" },
    awarded: {
      validate: [],
      // validate: [
      //   "req#Please enter the awarded",
      //   "max:10#Password should be maximum 10 Digits"
      // ]
    },
    courseTag: { validate: [] },
    currency: {
      validate: ["objValReq#Please select the currency"],
      obj: "value",
    },
    overview: { value: EditorState.createEmpty() },
    shortDesc: {
      validate: [
        "req#Enter the short description",
        "min:50#Minmum description should be 50 characters",
      ],
    },
    educationLevel: {
      validate: ["objValReq#Please select the education level"],
      obj: "value",
    },
    elPercentage: { allow: "float" },
    elPoints: { allow: "float" },
    elGrade: { allow: "float" },
    elCgpa: { allow: "float" },
    elGpa: { allow: "float" },
    isPublished: { value: false },
  });

  const formFieldsB = {
    name: { validate: ["req#Enter the course name"] },
    university: {
      validate: ["objValReq#Please select the university"],
      obj: "value",
    },
  };

  const [formFieldsModalA, setFormFieldsModalA] = useState({
    key: {},
    examType: {
      validate: ["objValReq#Please select the exam"],
      obj: "value",
    },
    listing: {
      validate: ["req"],
      allow: "float",
    },
    reading: { validate: ["req"], allow: "float" },
    writing: { validate: ["req"], allow: "float" },
    speaking: { validate: ["req"], allow: "float" },
    total: {
      validate: ["req"],
      allow: "float",
    },
  });

  const formFieldsModalB = {
    key: {},
    title: { validate: ["req"] },
    // hourLecture: { validate: ["req"] },
    // sessions: { validate: ["req"] },
    description: { validate: ["req"] },
  };

  const formFieldsModalC = {
    key: {},
    year: { validate: ["objValReq"], obj: "value" },
    month: { validate: ["objValReq"], obj: "0.value" },
  };

  // validate
  const {
    fields: fieldsA,
    errors: errorsA,
    handleChange: handleChangeA,
    handleSubmit: handleSubmitA,
    setMultiValue: setMultiValueA,
  } = AmlHashForm(formFieldsA);

  const {
    fields: fieldsB,
    errors: errorsB,
    handleChange: handleChangeB,
    handleSubmit: handleSubmitB,
    setMultiValue: setMultiValueB,
  } = AmlHashForm(formFieldsB);

  const {
    fields: fieldsModalA,
    errors: errorsModalA,
    handleChange: handleChangeModalA,
    handleSubmit: handleSubmitModalA,
    handleReset: handleResetModalA,
    setMultiValue: setMultiValueModalA,
    setValidator: setValidatorModalA,
  } = AmlHashForm(formFieldsModalA);

  const {
    fields: fieldsModalB,
    errors: errorsModalB,
    handleChange: handleChangeModalB,
    handleSubmit: handleSubmitModalB,
    handleReset: handleResetModalB,
    setMultiValue: setMultiValueModalB,
  } = AmlHashForm(formFieldsModalB);

  const {
    fields: fieldsModalC,
    errors: errorsModalC,
    handleChange: handleChangeModalC,
    handleSubmit: handleSubmitModalC,
    handleReset: handleResetModalC,
    setMultiValue: setMultiValueModalC,
  } = AmlHashForm(formFieldsModalC);

  // effect
  useEffect(() => {
    if (id) {
      getInfo();
    }

    setMultiValueA({
      elCgpaMax: { label: "10", value: 10 },
      elGpaMax: { label: "10", value: 10 },
      elPointsMax: { label: "10", value: 10 },
    });
    setInit(true);
  }, []);

  console.log(fieldsA);
  useEffect(() => {
    if (init) {
      setValidatorModalA(formFieldsModalA);
    }
  }, [formFieldsModalA]);

  useEffect(() => {
    if (testReload !== null) {
      setTest();
    }
  }, [testReload]);

  // api
  const getInfo = () => {
    let data = {
      url: "course",
      method: "GET",
      query: `/${id}`,
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        setFields(res.data);
        setStatus("success");
      } else {
        setStatus("error");
      }
    });
  };

  const onSubmit = async (type) => {
    let allow = false;
    setSubmit(type);
    if (type === "publish") {
      let checkA = handleSubmitA();
      if (checkA) {
        allow = true;
      }
    } else if (type === "draft") {
      let checkA = handleSubmitB();
      if (checkA) {
        allow = true;
      }
    }

    if (allow) {
      setLoading(type);
      let params = getFields();
      params.isPublished = type === "publish" ? true : false;
      // call
      let data = {};
      if (id) {
        data = {
          url: "course",
          method: "PUT",
          query: `/${id}`,
          body: params,
        };
      } else {
        data = { url: "course", method: "POST", query: "", body: params };
      }

      api.call(data, (res) => {
        if (res.status === 200) {
          common.notify(
            "S",
            `Course has been ${
              type === "publish" ? "published successfully" : "saved to draft"
            }`
          );
          navigate("/course", { state });
        } else {
          common.notify("E", res.data.error);
        }
        setLoading(null);
      });
    }
  };

  // modal submit
  const onModalSubmitA = async () => {
    let allow = handleSubmitModalA();
    if (allow) {
      setExam((prev) => {
        let nData = [...prev];
        let data = {
          examType: fieldsModalA.examType.value,
          listing: fieldsModalA.listing,
          reading: fieldsModalA.reading,
          writing: fieldsModalA.writing,
          speaking: fieldsModalA.speaking,
          total: fieldsModalA.total,
        };
        if (fieldsModalA.key === "") {
          nData.push(data);
        } else {
          nData[fieldsModalA.key] = data;
        }
        return nData;
      });
      closeCourse();
    }
  };

  const onModalSubmitB = async () => {
    let allow = handleSubmitModalB();
    if (allow) {
      setModules((prev) => {
        let nData = [...prev];
        let data = {
          title: fieldsModalB.title,
          hourLecture: fieldsModalB.hourLecture,
          sessions: fieldsModalB.sessions,
          description: fieldsModalB.description,
        };
        if (fieldsModalB.key === "") {
          nData.push(data);
        } else {
          nData[fieldsModalB.key] = data;
        }
        return nData;
      });
      closeModule();
    }
  };

  const onModalSubmitC = async () => {
    let allow = handleSubmitModalC();
    // check year
    if (fieldsModalC.key === "") {
      intake.map((i) => {
        if (i.year === fieldsModalC.year?.value) {
          allow = false;
          setError((prev) => ({ prev, intakeYear: "Year already selected" }));
          handleSubmitModalC();
        }
      });
    }
    if (allow) {
      setIntake((prev) => {
        let nData = [...prev];
        let data = {
          year: fieldsModalC.year?.value,
          month: fieldsModalC.month.map((i) => Number(i.value)),
        };
        if (fieldsModalC.key === "") {
          nData.push(data);
        } else {
          nData[fieldsModalC.key] = data;
        }
        return nData;
      });
      closeIntake();
    }
  };

  // support
  const setFields = (result) => {
    // fields 1
    setMultiValueA({
      name: result.name,
      university: {
        label: result.university.name,
        value: result.university._id,
      },
      subject: result.subject.map((i) => ({ label: i.name, value: i._id })),
      courseLevel: common.SetSelect(result.courseLevel),
      attendanceType: common.SetArrayToSelect(result.attendanceType),
      courseDurationType: result.courseDurationType
        ? {
            label: `${result.courseDurationType} Year`,
            value: result.courseDurationType,
          }
        : null,
      tuitionFee: result.tuitionFee || "",
      cab: result?.cab || "",
      agcy: result?.agcy || "",
      cbCy:
        common.SetSelect(result?.cbCy) ||
        common.SetArrayToSelect(types.currency)[0],
      agcyCom:
        common.SetSelect(result?.agcyCom) ||
        common.SetArrayToSelect(types.commission)[0],
      awarded: result?.awarded || "",
      courseTag: result.courseTag,
      currency: common.SetSelect(result.currency),
      overview: setHtml(result.overview),
      shortDesc: result.shortDesc,
      otherAcademic: result.otherAcademic,
      carrierPath: result.carrierPath,
      educationLevel: result?.educationLevel?.name
        ? {
            label: result.educationLevel?.name,
            value: result.educationLevel._id,
          }
        : null,
      elPercentage: result.elPercentage || "",
      elPoints: result.elPoints || "",
      elGrade: result.elGrade
        ? {
            label: types.gradeValue[result.elGrade],
            value: result.elGrade,
          }
        : "",
      elCgpa: result.elCgpa || "",
      elGpa: result.elGpa || "",
      isPublished: result.isPublished,
      elCgpaMax: {
        label: result?.elCgpaMax ? result?.elCgpaMax : 10,
        value: result?.elCgpaMax ? result?.elCgpaMax : 10,
      },
      elGpaMax: {
        label: result?.elGpaMax ? result?.elGpaMax : 10,
        value: result?.elGpaMax ? result?.elGpaMax : 10,
      },
      elPointsMax: {
        label: result?.elPointsMax ? result?.elPointsMax : 10,
        value: result?.elPointsMax ? result?.elPointsMax : 10,
      },
    });
    // field 2
    setMultiValueB({
      name: result.name,
      university: {
        label: result.university.name,
        value: result.university._id,
      },
    });
    // fields 3
    setExam(
      result.exam.map((i) => {
        delete i._id;
        return i;
      })
    );
    setModules(
      result.modules.map((i) => {
        delete i._id;
        return i;
      })
    );
    setIntake(
      result.intake.map((i) => {
        delete i._id;
        return i;
      })
    );
  };

  const getFields = () => {
    let res = {
      name: fieldsA.name,
      university: fieldsA.university?.value || null,
      subject: fieldsA.subject ? fieldsA.subject.map((i) => i.value) : [],
      courseLevel: fieldsA.courseLevel?.value || "",
      attendanceType: fieldsA.attendanceType
        ? fieldsA.attendanceType.map((i) => i.value)
        : [],
      courseDurationType: fieldsA.courseDurationType?.value || "",
      tuitionFee: fieldsA.tuitionFee,
      awarded: fieldsA.awarded,
      cab: fieldsA?.cab,
      agcy: fieldsA?.agcy,
      cbCy: fieldsA.cbCy?.value || common.SetArrayToSelect(types.currency)[0]?.value,
      agcyCom:
        fieldsA.agcyCom?.value || common.SetArrayToSelect(types.commission)[0]?.value,
      courseTag: fieldsA.courseTag,
      currency: fieldsA.currency?.value || "",
      overview: getLength("overview") > 1 ? getHtml("overview") : "",
      shortDesc: fieldsA.shortDesc,
      otherAcademic: fieldsA.otherAcademic,
      carrierPath: fieldsA.carrierPath,
      educationLevel: fieldsA.educationLevel?.value || "",
      elPercentage: fieldsA.elPercentage,
      elPoints: fieldsA.elPoints,
      elGrade: fieldsA.elGrade.value,
      elCgpa: fieldsA.elCgpa,
      elGpa: fieldsA.elGpa,
      elCgpaMax: fieldsA.elCgpaMax.value,
      elGpaMax: fieldsA.elGpaMax.value,
      elPointsMax: fieldsA.elPointsMax.value,
      exam: exam,
      modules: modules,
      intake: intake,
    };
    return res;
  };

  const setTest = () => {
    let err = "The value must be less than or equal to ";
    if (fieldsModalA.examType?.value === "IELTS") {
      setFormFieldsModalA((prev) => ({
        ...prev,
        listing: { validate: ["req", `lte:9#${err}9`], allow: "float" },
        reading: { validate: ["req", `lte:9#${err}9`], allow: "float" },
        writing: { validate: ["req", `lte:9#${err}9`], allow: "float" },
        speaking: { validate: ["req", `lte:9#${err}9`], allow: "float" },
        total: {
          validate: ["req", `lte:36#${err}36`],
          allow: "float",
        },
      }));
    } else if (fieldsModalA.examType?.value === "IELTS Ukvi") {
      setFormFieldsModalA((prev) => ({
        ...prev,
        listing: { validate: ["req", `lte:9#${err}9`], allow: "float" },
        reading: { validate: ["req", `lte:9#${err}9`], allow: "float" },
        writing: { validate: ["req", `lte:9#${err}9`], allow: "float" },
        speaking: { validate: ["req", `lte:9#${err}9`], allow: "float" },
        total: {
          validate: ["req", `lte:36#${err}36`],
          allow: "float",
        },
      }));
    } else if (fieldsModalA.examType?.value === "TOEFL") {
      setFormFieldsModalA((prev) => ({
        ...prev,
        listing: { validate: ["req", `lte:30#${err}30`], allow: "float" },
        reading: { validate: ["req", `lte:30#${err}30`], allow: "float" },
        writing: { validate: ["req", `lte:30#${err}30`], allow: "float" },
        speaking: { validate: ["req", `lte:30#${err}30`], allow: "float" },
        total: { validate: ["req", `lte:120#${err}120`], allow: "float" },
      }));
    } else {
      setFormFieldsModalA((prev) => ({
        ...prev,
        listing: { validate: ["req"], allow: "float" },
        reading: { validate: ["req"], allow: "float" },
        writing: { validate: ["req"], allow: "float" },
        speaking: { validate: ["req"], allow: "float" },
        total: { validate: ["req"], allow: "float" },
      }));
    }
  };

  // common
  const getHtml = (val) => {
    return draftToHtml(convertToRaw(fieldsA[val].getCurrentContent()));
  };

  const setHtml = (val) => {
    return EditorState.createWithContent(
      ContentState.createFromBlockArray(convertFromHTML(val))
    );
  };

  const getLength = (val) => {
    return common.htmlToString(getHtml(val)).length;
  };

  const onYes = () => {
    if (confirm[0] === "exam") {
      setExam((prev) => {
        let nData = [...prev];
        nData.splice(confirm[1], 1);
        return nData;
      });
    } else if (confirm[0] === "module") {
      setModules((prev) => {
        let nData = [...prev];
        nData.splice(confirm[1], 1);
        return nData;
      });
    } else if (confirm[0] === "intake") {
      setIntake((prev) => {
        let nData = [...prev];
        nData.splice(confirm[1], 1);
        return nData;
      });
    }
    setConfirm(null);
  };

  // course
  const editCourse = (key) => {
    setMultiValueModalA({
      key: key,
      examType: common.SetSelect(exam[key].examType),
      listing: exam[key].listing,
      reading: exam[key].reading,
      writing: exam[key].writing,
      speaking: exam[key].speaking,
      total: exam[key].total,
    });
    setTestReload(new Date());
    setMyModal("exam");
  };

  const editModule = (key) => {
    setMultiValueModalB({
      key: key,
      title: modules[key].title,
      hourLecture: modules[key].hourLecture,
      sessions: modules[key].sessions,
      description: modules[key].description,
    });
    setMyModal("module");
  };

  const editIntake = (key) => {
    setMultiValueModalC({
      key: key,
      year: common.SetSelect(intake[key].year),
      month: intake[key].month.map((i) => common.getMonthList()[i - 1]),
    });
    setMyModal("intake");
  };

  const closeCourse = () => {
    handleResetModalA("values");
    setMyModal(null);
  };

  const closeModule = () => {
    handleResetModalB("values");
    setMyModal(null);
  };

  const closeIntake = () => {
    setError((prev) => ({ ...prev, intakeYear: "" }));
    handleResetModalC("values");
    setMyModal(null);
  };

  // render
  return (
    <>
      <div className="title-wrap d-flex align-items-center">
        <img
          src={common.loadImg("back-icn.svg")}
          className="ed-cp"
          alt=""
          onClick={() => navigate("/course")}
        />
        <h1 className="page-title ps-4">{id ? "Edit" : "Add New"} Course</h1>
      </div>
      {status !== "success" ? (
        <StatusBar status={status} />
      ) : (
        <Fragment>
          <div className="content-wrap form-page">
            <div className="container-fluid">
              <h2 className="form-title">Course Info</h2>
              <div className="form-wrap">
                <div className="row">
                  <div className="col-md-6 mb-4">
                    <label>Course Name</label>
                    <input
                      type="text"
                      name="name"
                      placeholder="Enter Course name"
                      className="form-control"
                      value={fieldsA.name}
                      onChange={(e) => {
                        handleChangeA(e);
                        handleChangeB(e);
                      }}
                    />
                    {submit === "publish" && (
                      <p className="error-txt">{errorsA.name}</p>
                    )}
                    {submit === "draft" && (
                      <p className="error-txt">{errorsB.name}</p>
                    )}
                  </div>
                  <div className="col-md-6 mb-4">
                    <label>Select University</label>
                    <FieldSelect
                      api="university"
                      query="?offset=1&limit=1000"
                      get="name"
                      set="_id"
                      value={fieldsA.university}
                      placeholder="Please select the university"
                      render={"yes"}
                      clearable={true}
                      finder={false}
                      multi={false}
                      onSelect={(data) => {
                        setMultiValueA({
                          university: data,
                        });
                        setMultiValueB({
                          university: data,
                        });
                      }}
                    />
                    {submit === "publish" && (
                      <p className="error-txt">{errorsA.university}</p>
                    )}
                    {submit === "draft" && (
                      <p className="error-txt">{errorsB.university}</p>
                    )}
                  </div>
                  <div className="col-md-6 mb-4">
                    <label>Select Subject</label>
                    <FieldSelect
                      api="subject"
                      query="?offset=1&limit=1000"
                      get="name"
                      set="_id"
                      value={fieldsA.subject}
                      placeholder="Please select the subject"
                      render={"yes"}
                      multi={true}
                      clearable={true}
                      finder={false}
                      onSelect={(data) => {
                        setMultiValueA({
                          subject: data,
                        });
                      }}
                    />
                    {submit === "publish" && (
                      <p className="error-txt">{errorsA.subject}</p>
                    )}
                  </div>
                  <div className="col-md-6 mb-4">
                    <label>Course Level</label>
                    <Select
                      className="custom-select-box"
                      value={fieldsA.courseLevel}
                      placeholder="Please select the course level"
                      options={common.SetArrayToSelect(types.courseLevel)}
                      isClearable={true}
                      onChange={(data) => {
                        setMultiValueA({
                          courseLevel: data,
                        });
                      }}
                    />
                    {submit === "publish" && (
                      <p className="error-txt">{errorsA.courseLevel}</p>
                    )}
                  </div>
                  <div className="col-md-6 mb-4">
                    <label>Course Type</label>
                    <Select
                      className="custom-select-box"
                      value={fieldsA.attendanceType}
                      options={common.SetArrayToSelect(types.courseType)}
                      isClearable={true}
                      isMulti={true}
                      onChange={(data) => {
                        setMultiValueA({
                          attendanceType: data,
                        });
                      }}
                    />
                    {submit === "publish" && (
                      <p className="error-txt">{errorsA.attendanceType}</p>
                    )}
                  </div>
                  <div className="col-md-6 mb-4">
                    <label>Course Length</label>
                    <Select
                      className="custom-select-box"
                      value={fieldsA.courseDurationType}
                      options={types.courseLength.map((i) => ({
                        label: `${i} Year`,
                        value: i,
                        isDisabled: false,
                      }))}
                      isClearable={true}
                      onChange={(data) => {
                        setMultiValueA({
                          courseDurationType: data,
                        });
                      }}
                    />
                    {submit === "publish" && (
                      <p className="error-txt">{errorsA.courseDurationType}</p>
                    )}
                  </div>
                  <div className="col-md-6 mb-4">
                    <label>Course Fee</label>
                    <div className="d-flex">
                      <div className="col-md-7">
                        <input
                          type="text"
                          name="tuitionFee"
                          placeholder="Enter Course fees"
                          className="form-control select-left"
                          value={fieldsA.tuitionFee}
                          onChange={handleChangeA}
                        />
                      </div>
                      <div className="col-md-5">
                        <Select
                          className="custom-select-box"
                          value={fieldsA.currency}
                          options={common.SetArrayToSelect(types.currency)}
                          isClearable={true}
                          onChange={(data) => {
                            setMultiValueA({
                              currency: data,
                            });
                          }}
                        />
                      </div>
                    </div>
                    {submit === "publish" && (
                      <p className="error-txt">
                        {errorsA.tuitionFee || errorsA.currency}
                      </p>
                    )}
                  </div>
                  <div className="col-md-6 mb-4">
                    <label>Awarded</label>
                    <input
                      type="text"
                      name="awarded"
                      placeholder="Enter Awarded"
                      className="form-control"
                      value={fieldsA.awarded}
                      onChange={(e) => {
                        handleChangeA(e);
                      }}
                    />
                    {submit === "publish" && (
                      <p className="error-txt">{errorsA.awarded}</p>
                    )}
                  </div>

                  <div className="col-md-6 mb-4">
                  <label>Cash Back</label>
                  <div className="d-flex">
                    <div className="col-md-7">
                      <input
                        type="text"
                        name="cab"
                        placeholder="Cash Back"
                        className="form-control select-left"
                        value={fieldsA?.cab}
                        onChange={(e) => {
                          handleChangeA(e);
                        }}
                      />
                    </div>
                    <div className="col-md-5">
                      <Select
                        className="custom-select-box"
                        value={fieldsA.cbCy}
                        options={common.SetArrayToSelect(types.currency)}
                        isClearable={true}
                        onChange={(data) => {
                          setMultiValueA({
                            cbCy: data,
                          });
                        }}
                      />
                    </div>
                  </div>
                  {submit === "publish" && (
                      <p className="error-txt">
                        {errorsA.cab || errorsA.cbCy}
                      </p>
                  )}
                </div>
                <div className="col-md-6 mb-4">
                  <label>Agent Commission</label>
                  <div className="d-flex">
                    <div className="col-md-7">
                      <input
                        type="text"
                        name="agcy"
                        placeholder="Agent Commission"
                        className="form-control select-left"
                        value={fieldsA?.agcy}
                        maxLength={
                          fieldsA?.agcyCom?.value === "Percentage" ? 3 : ""
                        }
                        onChange={(e) => {
                          if (fieldsA?.agcyCom?.value === "Percentage") {
                            if (e.target.value <= 100) {
                              handleChangeA(e);
                            }
                          } else {
                            handleChangeA(e);
                          }
                        }}
                      />
                    </div>
                    <div className="col-md-5">
                      <Select
                        className="custom-select-box"
                        value={fieldsA.agcyCom}
                        options={common.SetArrayToSelect(types.commission)}
                        isClearable={true}
                        onChange={(data) => {
                          if (data?.value === "Percentage") {
                            setMultiValueA({
                              agcy: "",
                              agcyCom: data,
                            });
                          } else {
                            setMultiValueA({
                              agcyCom: data,
                            });
                          }
                        }}
                      />
                    </div>
                  </div>
                  {/* {submit === "publish" && (
                      <p className="error-txt">
                        {errorsA.agcy || errorsA.agcyCom}
                      </p>
                    )} */}
                </div>

                  <div className="col-md-6 mb-4">
                    <label>Tag</label>
                    <input
                      type="text"
                      name="courseTag"
                      placeholder="Enter Tag"
                      className="form-control"
                      value={fieldsA.courseTag}
                      onChange={(e) => {
                        handleChangeA(e);
                      }}
                    />
                    {submit === "publish" && (
                      <p className="error-txt">{errorsA.courseTag}</p>
                    )}
                  </div>
                </div>
              </div>
              <h2 className="form-title">Course Overview</h2>
              <div className="form-wrap">
                <div className="row">
                  <div className="col-12 mb-4">
                    <label>Brief of Course</label>
                    <Editor
                      editorState={fieldsA.overview}
                      onEditorStateChange={(e) =>
                        setMultiValueA({ overview: e })
                      }
                      wrapperClassName="wrapper-class"
                      editorClassName="editor-class"
                      toolbarClassName="toolbar-class"
                      toolbar={{
                        options: ["blockType", "list"],
                        blockType: {
                          inDropdown: true,
                          options: ["H1", "H2", "H3", "H4", "H5", "H6"],
                          className: undefined,
                          component: undefined,
                          dropdownClassName: undefined,
                        },
                        list: {
                          inDropdown: false,
                          className: undefined,
                          component: undefined,
                          dropdownClassName: undefined,
                          options: ["unordered", "ordered"],
                        },
                      }}
                    />
                    {submit === "publish" && getLength("overview") <= 200 && (
                      <p className="error-txt">Content length too low</p>
                    )}
                  </div>
                  <div className="col-12">
                    <label>Short Description</label>
                    <textarea
                      name="shortDesc"
                      placeholder="Enter Short Description"
                      className="form-control select-left"
                      onChange={handleChangeA}
                      value={fieldsA.shortDesc}
                    />
                    {submit === "publish" && (
                      <p className="error-txt">{errorsA.shortDesc}</p>
                    )}
                  </div>
                </div>
              </div>
              <h2 className="form-title">Admission Requirements</h2>
              <div className="form-wrap">
                <div className="row">
                  <div className="col-12">
                    <label className="univ-detail-label">
                      Academic Background
                    </label>
                  </div>
                  <div className="col-md-12 mb-4">
                    <label>Highest Level of Education</label>
                    <Select
                      className="custom-select-box"
                      value={fieldsA.educationLevel}
                      options={types.educationLevel}
                      isClearable={true}
                      onChange={(data) => {
                        setMultiValueA({
                          educationLevel: data,
                        });
                      }}
                    />
                    {submit === "publish" && (
                      <p className="error-txt">{errorsA.educationLevel}</p>
                    )}
                  </div>
                  {/* Grades */}
                  <div className="col-12 row">
                    <div className="col-md-6 mb-4">
                      <label>Grade Scale</label>
                      <input
                        type="text"
                        className="form-control select-left"
                        value={100}
                        disabled
                      />
                    </div>
                    <div className="col-md-6 mb-4">
                      <label>Percentage</label>
                      <input
                        type="text"
                        maxLength={3}
                        name="elPercentage"
                        placeholder="Enter Percentage"
                        className="form-control select-left"
                        value={fieldsA.elPercentage}
                        onChange={(e) => {
                          if (Number(e.target.value) <= 100) {
                            handleChangeA(e);
                          }
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-12 row">
                    <div className="col-md-6 mb-4">
                      <label>Grade Scale</label>
                      <Select
                        className="custom-select-box"
                        value={fieldsA.elPointsMax}
                        options={types.gradeScale}
                        isClearable={true}
                        onChange={(data) => {
                          setMultiValueA({
                            elPointsMax: data,
                            elPoints: "",
                          });
                        }}
                      />
                    </div>
                    <div className="col-md-6 mb-4">
                      <label>Points</label>
                      <input
                        type="text"
                        name="elPoints"
                        placeholder="Enter points"
                        className="form-control select-left"
                        value={fieldsA.elPoints}
                        maxLength={fieldsA?.elPointsMax?.value}
                        onChange={(e) => {
                          if (
                            Number(e.target.value) <= Number(e.target.maxLength)
                          ) {
                            handleChangeA(e);
                          }
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-12 row">
                    <div className="col-md-6 mb-4">
                      <label>Grade Scale</label>
                      <Select
                        className="custom-select-box"
                        value={fieldsA.elCgpaMax}
                        options={types.gradeScale}
                        isClearable={true}
                        onChange={(data) => {
                          setMultiValueA({
                            elCgpaMax: data,
                            elCgpa: "",
                          });
                        }}
                      />
                    </div>
                    <div className="col-md-6 mb-4">
                      <label>CGPA</label>
                      <input
                        type="number"
                        name="elCgpa"
                        placeholder="Enter CGPA"
                        className="form-control select-left"
                        value={fieldsA.elCgpa}
                        maxLength={Number(fieldsA?.elCgpaMax?.value)}
                        onChange={(e) => {
                          if (
                            Number(e.target.value) <= Number(e.target.maxLength)
                          ) {
                            handleChangeA(e);
                          }
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-12 row">
                    <div className="col-md-6 mb-4">
                      <label>Grade Scale</label>
                      <Select
                        className="custom-select-box"
                        value={fieldsA.elGpaMax}
                        options={types.gradeScale}
                        isClearable={true}
                        onChange={(data) => {
                          setMultiValueA({
                            elGpaMax: data,
                            elGpa: "",
                          });
                        }}
                      />
                    </div>
                    <div className="col-md-6 mb-4">
                      <label>GPA</label>
                      <input
                        type="number"
                        name="elGpa"
                        placeholder="Enter GPA"
                        className="form-control select-left"
                        value={fieldsA.elGpa}
                        maxLength={fieldsA?.elGpaMax?.value}
                        onChange={(e) => {
                          if (
                            Number(e.target.value) <= Number(e.target.maxLength)
                          ) {
                            handleChangeA(e);
                          }
                        }}
                      />
                    </div>
                  </div>
                  {/* Grades */}

                  <div className="col-12">
                    <label>Academic Requirements</label>
                    <textarea
                      name="otherAcademic"
                      placeholder="Enter Academic Requirements"
                      className="form-control select-left"
                      onChange={handleChangeA}
                      value={fieldsA.otherAcademic}
                    />
                    {submit === "publish" && (
                      <p className="error-txt">{errorsA.otherAcademic}</p>
                    )}
                  </div>

                  <div className="col-12 mt-3">
                    <label>Carrier Path</label>
                    <textarea
                      name="carrierPath"
                      placeholder="Enter Carrier Path"
                      className="form-control select-left"
                      onChange={handleChangeA}
                      value={fieldsA.carrierPath}
                    />
                    {submit === "publish" && (
                      <p className="error-txt">{errorsA.carrierPath}</p>
                    )}
                  </div>

                  <div className="col-12">
                    <label className="univ-detail-label mt-4">
                      Maximum Language Score
                    </label>
                    {exam.length === 0 ? (
                      <div className="create-btn-wrap d-flex align-items-center">
                        <div>
                          <p className="univ-detail-p mb-0">
                            Qui ipsorum lingua Celtae, nostra Galli appellantur.
                            Quisque ut dolor gravida, placerat libero vel,
                            euismod. Hi omnes lingua, institutis, legibus inter
                            se differunt.
                          </p>
                        </div>
                        <div>
                          <button
                            className="btn btn-blue"
                            onClick={() => setMyModal("exam")}
                          >
                            <span>Create Test Score</span>
                            <img
                              src={common.loadImg(
                                "arrow-righti-circle-white.svg"
                              )}
                              className="ed-cp ps-3"
                              alt=""
                            />
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div className="max-score-details">
                        {exam.map((i, k) => (
                          <div className="mb-4" key={k}>
                            <div className="inner-detail-row-head d-flex align-items-center justify-content-between">
                              <div>
                                <label>{i.examType}</label>
                                <p>{i.total}</p>
                              </div>
                              <div>
                                <button
                                  className="btn btn-white"
                                  onClick={() => editCourse(k)}
                                >
                                  <span>Edit</span>
                                  <img
                                    src={common.loadImg(
                                      "arrow-righti-circle-white.svg"
                                    )}
                                    alt=""
                                    className="ps-2"
                                  />
                                </button>
                                <button
                                  className="btn btn-white ms-2"
                                  onClick={() => setConfirm(["exam", k])}
                                >
                                  <span>Delete</span>
                                  <img
                                    src={common.loadImg(
                                      "arrow-righti-circle-white.svg"
                                    )}
                                    alt=""
                                    className="ps-2"
                                  />
                                </button>
                              </div>
                            </div>
                            <div className="inner-detail-row-body">
                              <div className="row">
                                <div className="col-md-3">
                                  <label>Reading</label>
                                  <p>{i.reading}</p>
                                </div>
                                <div className="col-md-3">
                                  <label>Writing</label>
                                  <p>{i.writing}</p>
                                </div>
                                <div className="col-md-3">
                                  <label>Listening</label>
                                  <p>{i.listing}</p>
                                </div>
                                <div className="col-md-3">
                                  <label>Speaking</label>
                                  <p>{i.speaking}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                        <div className="inner-detail-btn-wrap">
                          <button
                            className="btn btn-rarrow"
                            onClick={() => {
                              setMyModal("exam");
                            }}
                          >
                            Add Test Score
                          </button>
                        </div>
                      </div>
                    )}
                    {/* Max score details after added end here */}
                  </div>
                </div>
              </div>
              <h2 className="form-title">Modules</h2>
              <div className="form-wrap">
                {modules.length === 0 ? (
                  <div className="row">
                    <div className="col-12">
                      <div className="create-btn-wrap d-flex align-items-center">
                        <div>
                          <p className="univ-detail-p mb-0">
                            Qui ipsorum lingua Celtae, nostra Galli appellantur.
                            Quisque ut dolor gravida, placerat libero vel,
                            euismod. Hi omnes lingua, institutis, legibus inter
                            se differunt.
                          </p>
                        </div>
                        <div>
                          <button
                            className="btn btn-blue"
                            onClick={() => setMyModal("module")}
                          >
                            <span>Create Module</span>
                            <img
                              src={common.loadImg(
                                "arrow-righti-circle-white.svg"
                              )}
                              className="ed-cp ps-3"
                              alt=""
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="max-score-details">
                    {modules.map((i, k) => (
                      <div className="mb-4" key={k}>
                        <div className="inner-detail-row-head d-flex align-items-center justify-content-between">
                          <div>
                            <p>{i.title}</p>
                            <label>
                              {i.hourLecture} | {i.sessions}
                            </label>
                          </div>

                          <div>
                            <button
                              className="btn btn-white"
                              onClick={() => editModule(k)}
                            >
                              <span>Edit</span>
                              <img
                                src={common.loadImg(
                                  "arrow-righti-circle-white.svg"
                                )}
                                alt=""
                                className="ps-2"
                              />
                            </button>
                            <button
                              className="btn btn-white ms-2"
                              onClick={() => setConfirm(["module", k])}
                            >
                              <span>Delete</span>
                              <img
                                src={common.loadImg(
                                  "arrow-righti-circle-white.svg"
                                )}
                                alt=""
                                className="ps-2"
                              />
                            </button>
                          </div>
                        </div>
                        <div className="inner-detail-row-body">
                          <div className="row">
                            <div className="col-12">
                              <p className="univ-detail-p">{i.description}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                    <div className="inner-detail-btn-wrap">
                      <button
                        className="btn btn-rarrow"
                        onClick={() => setMyModal("module")}
                      >
                        Add Module
                      </button>
                    </div>
                  </div>
                )}
              </div>
              <h2 className="form-title">Intake Dates</h2>
              <div className="form-wrap">
                {intake.length === 0 ? (
                  <div className="col-12">
                    <label className="univ-detail-label">Intakes</label>

                    <div className="create-btn-wrap d-flex align-items-center">
                      <div>
                        <p className="univ-detail-p mb-0">
                          Qui ipsorum lingua Celtae, nostra Galli appellantur.
                          Quisque ut dolor gravida, placerat libero vel,
                          euismod. Hi omnes lingua, institutis, legibus inter se
                          differunt.
                        </p>
                      </div>
                      <div>
                        <button
                          className="btn btn-blue"
                          onClick={() => setMyModal("intake")}
                        >
                          <span>Add Intake</span>
                          <img
                            src={common.loadImg(
                              "arrow-righti-circle-white.svg"
                            )}
                            className="ed-cp ps-3"
                            alt=""
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="row">
                    <div className="row">
                      <div className="d-flex align-items-center justify-content-between ed-bb">
                        <div>
                          <label className="univ-detail-label">
                            Intake List
                          </label>
                        </div>
                        <div>
                          <button
                            className="btn btn-rarrow"
                            onClick={() => setMyModal("intake")}
                          >
                            Add Intake
                          </button>
                        </div>
                      </div>
                      <div className="row pt-4">
                        <div className="col-md-2">
                          <label>Year</label>
                        </div>
                        <div className="col-md-7">
                          <label>Months</label>
                        </div>
                        <div className="col-md-3">
                          <label>Action</label>
                        </div>
                      </div>
                      <ul className="row course-sem-wrap pt-4">
                        {intake.map((i, k) => (
                          <li key={k}>
                            <div className="row mb-3">
                              <div className="col-md-2">
                                <p className="status-txt">{i.year}</p>
                              </div>

                              <div className="col-md-7 course-month-wrap">
                                {i.month.map((cI, cK) => (
                                  <span className="course-sem-month" key={cK}>
                                    {common.getMonthList()[cI - 1].label}
                                  </span>
                                ))}
                              </div>
                              <div className="col-md-3">
                                <img
                                  src={common.loadImg("edit-btn.svg")}
                                  alt=""
                                  className="sem-edit-icn me-2 ed-cp"
                                  onClick={() => editIntake(k)}
                                />
                                <img
                                  src={common.loadImg("delete-icn.svg")}
                                  alt=""
                                  className="sem-del-icn ed-cp"
                                  onClick={() => setConfirm(["intake", k])}
                                />
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="inner-footer">
            <button
              className="btn ed-btn-default ed-btn-cancel me-3"
              disabled={loading !== null}
              onClick={() => navigate("/course")}
            >
              Cancel
            </button>
            {!fieldsA.isPublished && (
              <button
                className="btn ed-btn-default ed-btn-save-dt me-3"
                disabled={loading !== null}
                onClick={() => onSubmit("draft")}
              >
                {loading === "draft" ? "Processing..." : "Save & Draft"}
              </button>
            )}
            <button
              className="btn ed-btn-default ed-btn-save-pub"
              onClick={() => onSubmit("publish")}
              disabled={loading !== null}
            >
              {loading === "publish" ? "Publishing..." : "Save & Publish"}
            </button>
          </div>
        </Fragment>
      )}

      <Modal
        isOpen={myModal === "exam"}
        style={common.modalStyle(75)}
        ariaHideApp={false}
        contentLabel="Modal"
      >
        <div className="container-fluid">
          <div className="row modal-header">
            <div className="col-12 d-flex justify-content-between align-items-center">
              <p className="modal-title mb-0">
                {fieldsModalA.key === "" ? "Add" : "Edit"} Test Score
              </p>
              <img
                src={common.loadImg("close-icn.svg")}
                alt="arrow"
                onClick={closeCourse}
                className="ed-cp img-fluid"
              />
            </div>
          </div>
          <div className="row modal-body">
            <div className="form-wrap">
              <div className="row">
                <div className="col-md-6 mb-4">
                  <label>Test Name</label>
                  <Select
                    className="custom-select-box"
                    value={fieldsModalA.examType}
                    options={common.SetArrayToSelect(types.testType)}
                    isClearable={true}
                    onChange={(data) => {
                      setMultiValueModalA({
                        examType: data,
                      });
                      setTestReload(new Date());
                    }}
                  />
                  <p className="error-txt">{errorsModalA.examType}</p>
                </div>
                <div className="col-md-6 mb-4">
                  <label>Over all Score</label>
                  <input
                    type="text"
                    name="total"
                    placeholder="Over all Score"
                    className="form-control"
                    value={fieldsModalA.total}
                    onChange={(e) => {
                      handleChangeModalA(e);
                    }}
                  />
                  <p className="error-txt">{errorsModalA.total}</p>
                </div>
                <div className="col-12">
                  <label>Select Type</label>
                  <div className="d-flex align-items justify-content-between">
                    {["listing", "reading", "writing", "speaking"].map(
                      (i, k) => (
                        <div key={k}>
                          <label htmlFor={i}>
                            {i == "listing" ? "LISTENING" : i.toUpperCase()}
                          </label>
                          <input
                            type="text"
                            id={i}
                            name={i}
                            value={fieldsModalA[i]}
                            placeholder={`Enter ${i}`}
                            className="form-control"
                            onChange={(e) => {
                              handleChangeModalA(e);
                            }}
                          />
                          <p className="error-txt">{errorsModalA[i]}</p>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              className="btn ed-btn-default ed-btn-save-dt"
              onClick={onModalSubmitA}
            >
              Save &amp; Update
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={myModal === "module"}
        style={common.modalStyle(75)}
        ariaHideApp={false}
        contentLabel="Modal"
      >
        <div className="container-fluid">
          <div className="row modal-header">
            <div className="col-12 d-flex justify-content-between align-items-center">
              <p className="modal-title mb-0">
                {fieldsModalA.key === "" ? "Add" : "Edit"} Module
              </p>
              <img
                src={common.loadImg("close-icn.svg")}
                alt="arrow"
                onClick={closeModule}
                className="ed-cp img-fluid"
              />
            </div>
          </div>
          <div className="row modal-body">
            <div className="form-wrap">
              <div className="row">
                <div className="col-md-12 mb-4">
                  <label>Module Name</label>
                  <input
                    type="text"
                    name="title"
                    value={fieldsModalB.title}
                    placeholder={`Enter Name`}
                    className="form-control"
                    onChange={handleChangeModalB}
                  />
                  <p className="error-txt">{errorsModalB.title}</p>
                </div>
                <div className="col-md-6 mb-4">
                  <label>How many hours?</label>
                  <input
                    type="text"
                    name="hourLecture"
                    value={fieldsModalB.hourLecture}
                    placeholder={`Enter Hours`}
                    className="form-control"
                    onChange={handleChangeModalB}
                  />
                  <p className="error-txt">{errorsModalB.hourLecture}</p>
                </div>
                <div className="col-md-6 mb-4">
                  <label>How many session in the week?</label>
                  <input
                    type="text"
                    name="sessions"
                    value={fieldsModalB.sessions}
                    placeholder={`Enter sessions`}
                    className="form-control"
                    onChange={handleChangeModalB}
                  />
                  <p className="error-txt">{errorsModalB.sessions}</p>
                </div>
                <div className="col-12">
                  <label>Description</label>
                  <input
                    type="text"
                    name="description"
                    value={fieldsModalB.description}
                    placeholder={`Enter description`}
                    className="form-control"
                    onChange={handleChangeModalB}
                  />
                  <p className="error-txt">{errorsModalB.description}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              className="btn ed-btn-default ed-btn-save-dt"
              onClick={onModalSubmitB}
            >
              Save &amp; Update
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={myModal === "intake"}
        style={common.modalStyle(75)}
        ariaHideApp={false}
        contentLabel="Modal"
      >
        <div className="container-fluid">
          <div className="row modal-header">
            <div className="col-12 d-flex justify-content-between align-items-center">
              <p className="modal-title mb-0">
                {fieldsModalA.key === "" ? "Add" : "Edit"} Intake
              </p>
              <img
                src={common.loadImg("close-icn.svg")}
                alt="arrow"
                onClick={closeIntake}
                className="ed-cp img-fluid"
              />
            </div>
          </div>
          <div className="row modal-body">
            <div className="form-wrap">
              <div className="row">
                <div className="col-md-6">
                  <label>Year</label>
                  <Select
                    value={fieldsModalC.year}
                    options={common.SetArrayToSelect(common.getFullYear(3))}
                    className="custom-select-box"
                    isClearable={true}
                    isDisabled={fieldsModalC.key !== ""}
                    onChange={(data) => {
                      setMultiValueModalC({ year: data });
                      setError((prev) => ({ ...prev, intakeYear: "" }));
                    }}
                  />
                  <p className="error-txt">
                    {error.intakeYear || errorsModalC.year}
                  </p>
                </div>
                <div className="col-md-6">
                  <label>Month</label>
                  <Select
                    value={fieldsModalC.month}
                    isMulti
                    options={common.getMonthList()}
                    className="custom-select-box"
                    isClearable={true}
                    onChange={(data) => setMultiValueModalC({ month: data })}
                  />
                  <p className="error-txt">{errorsModalC.month}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              className="btn ed-btn-default ed-btn-save-dt"
              onClick={onModalSubmitC}
            >
              Save &amp; Update
            </button>
          </div>
        </div>
      </Modal>
      {confirm && <ModalConfirm onYes={onYes} onNo={() => setConfirm(null)} />}
    </>
  );
}
export default Form;
