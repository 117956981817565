import React, { useState, useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { Container, Row, Col } from "react-bootstrap";
import { common } from "helpers";
import { Prev } from "react-bootstrap/esm/PageItem";

const FileUpload = (props) => {
  const { id, multiple } = props;
  // document
  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    let error = [];
    fileRejections.map((file) => {
      file.errors.map((err) => {
        error.push(err.message);
      });
    });
    if (error.length > 0) {
      common.notify("E", error[0]);
    } else if (props.onSuccess) {
      props?.onSuccess({ id, acceptedFiles });
    }
  }, []);

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    onDrop,
    multiple: multiple == false ? false : true,
    noClick: true,
    noKeyboard: true,
    maxFiles: 10,
    accept: {
      "image/*": [".png", ".gif", ".jpeg", ".jpg", ".pdf", ".doc"],
    },
  });

  return (
    <div
      {...getRootProps({ className: "dropzone" })}
      className="file-drag-drop-wrap mt-2"
    >
      <input {...getInputProps()} />
      <p className="drag-drop-input-lable mb-0">
        Drag and drop files here or{" "}
        <button type="button" onClick={open} className="browse-btn ms-2">
          Browse
        </button>
      </p>
    </div>
  );
};

export default FileUpload;
