import AmlHashForm from "aml-hash-form";
import {
  ContentState,
  convertFromHTML,
  convertToRaw,
  EditorState,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Fragment, useEffect, useRef, useState } from "react";
import Lightbox from "react-18-image-lightbox";
import { Editor } from "react-draft-wysiwyg";
import { Link, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
// includes
import FieldSelect from "elements/fieldSelect";
import FileUpload from "elements/fileUpload";
import GoogleMaps from "elements/googleMaps";
import StatusBar from "elements/statusBar";
import { api, common, types } from "helpers";

function Form() {
  // props
  const { id } = useParams();
  const navigate = useNavigate();
  const myRefs = useRef([]);
  // state
  const [status, setStatus] = useState(id ? "process" : "success");
  const [submit, setSubmit] = useState(null);
  const [loading, setLoading] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [selectRender, setSelectRender] = useState({
    country: "yes",
    province: "no",
    city: "no",
  });
  const [galleryImage, setGalleryImage] = useState(null);
  // fields
  const [fieldsB, setFieldsB] = useState({
    logo: "",
    brochureDocs: "",
    gallery: [],
    logoFile: [],
    brochureDocsFile: [],
    galleryFile: [],
  });

  // validate
  const formFields = {
    name: { validate: ["req"] },
    type: { validate: ["req"] },
    sinceYear: { validate: ["req"] },
    studentStrength: { validate: ["req"] },
    email: { validate: ["req"] },
    country: {
      validate: ["objValReq#Please select the country"],
      obj: "value",
    },
    province: {
      validate: ["objValReq#Please select the province"],
      obj: "value",
    },
    cab: { validate: [], allow: "float" },
    agcy: { validate: [], allow: "float" },
    promotionCode: { validate: [] },
    isView: { validate: [] },
    timesRanking: { validate: [] },
    avgCourseFee: { validate: [], allow: "float" },
    stuSatisfaction: { validate: [] },
    city: { validate: ["objValReq#Please select the city"], obj: "value" },
    description: { value: EditorState.createEmpty() },
    features: { value: EditorState.createEmpty() },
    institutionDetail: { value: EditorState.createEmpty() },
    coordinate: {
      validate: ["objValReq#Please select the location"],
      obj: "lat",
    },
    location: { validate: ["req#Please select the address"] },
    isPublished: { value: false },
    sortOrder: { validate: ["req"] },
  };

  const formFieldsD = {
    name: { validate: ["req"] },
  };

  // validate
  const {
    fields: fieldsA,
    errors,
    handleChange,
    handleSubmit: handleSubmitA,
    setMultiValue,
  } = AmlHashForm(formFields);

  const {
    errors: errorsD,
    handleChange: handleChangeD,
    handleSubmit: handleSubmitD,
    setMultiValue: setMultiValueD,
  } = AmlHashForm(formFieldsD);

  // effect
  useEffect(() => {
    if (id) {
      getInfo();
    }
  }, []);

  // api
  const getInfo = () => {
    let data = {
      url: "university",
      method: "GET",
      query: `/${id}`,
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        setFields(res.data);
        setStatus("success");
      } else {
        setStatus("error");
      }
    });
  };

  // functions
  const onFileChange = (e) => {
    const { name, files } = e.target;

    setFieldsB((prev) => ({
      ...prev,
      [name]: [...files],
    }));
  };

  const deleteFile = (key, name) => {
    setFieldsB((prev) => ({
      ...prev,
      [name]: prev[name].filter((i, k) => k !== key),
    }));
  };

  // handler
  const uploadFile = async (arr) => {
    var formdata = new FormData();
    arr.map((i) => {
      formdata.append("file", i);
    });
    let data = {
      url: "common_upload",
      method: "POST",
      body: formdata,
      ssr: true,
      cType: 6,
    };
    let resultData = await api.call(data);
    return resultData;
  };

  const uploadFileOnChange = async (binaryFile, type = "") => {
    if (binaryFile.length) {
      setDisabled(true);
      if (type === "gallery") {
        setFieldsB((prev) => {
          let nData = { ...prev };
          nData.galleryFile = [...fieldsB?.galleryFile, ...binaryFile];
          return nData;
        });
      }
      if (type === "logoFile") {
        setFieldsB((prev) => ({
          ...prev,
          ["logoFile"]: [...binaryFile],
        }));
      }

      if (type === "brochureDocsFile") {
        setFieldsB((prev) => ({
          ...prev,
          ["brochureDocsFile"]: [...binaryFile],
        }));
      }

      let result = await uploadFile(binaryFile);

      if (type === "gallery") {
        setFieldsB((prev) => {
          let nData = { ...prev };
          nData.gallery = [...fieldsB?.gallery, ...result?.data?.link];
          return nData;
        });
        let removeBinary = fieldsB?.galleryFile;
        result?.data?.link.map((item) => removeBinary.unshift());
        setFieldsB((prev) => {
          let nData = { ...prev };
          nData.galleryFile = [...removeBinary];
          return nData;
        });
        setDisabled(false);
      }

      if (type === "logoFile") {
        setFieldsB((prev) => {
          let nData = { ...prev };
          nData.logo = result?.data?.link?.toString();
          return nData;
        });
        let removeBinary = fieldsB?.logoFile;
        result?.data?.link.map((item) => removeBinary.unshift());
        setFieldsB((prev) => {
          let nData = { ...prev };
          nData.logoFile = [...removeBinary];
          return nData;
        });
        setDisabled(false);
      }

      if (type === "brochureDocsFile") {
        setFieldsB((prev) => {
          let nData = { ...prev };
          nData.brochureDocs = result?.data?.link?.toString();
          return nData;
        });
        let removeBinary = fieldsB?.brochureDocsFile;
        result?.data?.link.map((item) => removeBinary.unshift());
        setFieldsB((prev) => {
          let nData = { ...prev };
          nData.brochureDocsFile = [...removeBinary];
          return nData;
        });
        setDisabled(false);
      }
    }
  };

  const onSubmit = async (type) => {
    let allow = false;
    setSubmit(type);
    if (type === "publish") {
      let checkA = handleSubmitA();
      let checkB = handleSubmitB();
      if (checkA && checkB) {
        allow = true;
      }
    } else if (type === "draft") {
      let checkD = handleSubmitD();
      if (checkD) {
        allow = true;
      }
    }

    if (allow) {
      setLoading(type);
      let error = "";
      let params = getFields();
      params.isPublished = type === "publish" ? true : false;

      //old copy
      // await Promise.all(
      //   ["logo", "brochureDocs"].map(async (ip, kp) => {
      //     if (fieldsB[`${ip}File`].length > 0) {
      //       let result = await uploadFile(fieldsB[`${ip}File`]);
      //       if (result.status !== 200) {
      //         error = result.data.error;
      //       } else {
      //         if (ip === "gallery") {
      //           params.gallery = [...params.gallery, ...result.data.link];
      //         } else {
      //           params[ip] = result.data.link[0];
      //         }
      //       }
      //     }
      //   })
      // );

      if (error) {
        common.notify("E", error);
        setLoading(null);
      } else {
        let data = {};
        if (id) {
          data = {
            url: "university",
            method: "PUT",
            query: `/${id}`,
            body: params,
          };
        } else {
          data = { url: "university", method: "POST", query: "", body: params };
        }

        api.call(data, (res) => {
          if (res.status === 200) {
            common.notify(
              "S",
              `University has been ${
                type === "publish" ? "published successfully" : "saved to draft"
              }`
            );
            navigate("/university");
          } else {
            common.notify("E", res.data.error);
          }
          setLoading(null);
        });
      }
    }
  };

  const handleSubmitB = (val) => {
    let allow = true;
    // content length
    if (getLength("description") <= 200) {
      allow = false;
    } else if (getLength("institutionDetail") <= 50) {
      allow = false;
    } else {
      // file
      ["logo", "brochureDocs", "gallery"].map((i, k) => {
        if (fieldsB[i].length === 0 && fieldsB[`${i}File`].length === 0) {
          allow = false;
        }
      });
    }
    return allow;
  };

  // support
  const getHtml = (val) => {
    return draftToHtml(convertToRaw(fieldsA[val].getCurrentContent()));
  };

  const setHtml = (val) => {
    return EditorState.createWithContent(
      ContentState.createFromBlockArray(convertFromHTML(val))
    );
  };

  const getLength = (val) => {
    return common.htmlToString(getHtml(val)).length;
  };

  const setFields = (result) => {
    setMultiValue({
      name: result.name,
      type: result?.type
        ? common.FindSelect(types.UniversityType, result.type)
        : null,
      sinceYear: result.sinceYear,
      studentStrength: result.studentStrength,
      email: result.email,
      country: result.country
        ? {
            label: result.country?.name,
            value: result.country?._id,
          }
        : null,
      nationality: result.nationality?.map((item) => ({
        label: item.name,
        value: item._id,
      })),
      province: result.province
        ? {
            label: result.province?.name,
            value: result.province?._id,
          }
        : null,
      city: result.city
        ? {
            label: result.city?.name,
            value: result.city?._id,
          }
        : null,
      description: setHtml(result.description),
      features: setHtml(result.features),
      institutionDetail: setHtml(result.institutionDetail),
      coordinate: result.coordinate,
      location: result.location,
      isPublished: result.isPublished,
      visaPermit: result.visaPermit,
      scholarship: result.scholarship,
      cab: result?.cab || "",
      agcy: result?.agcy || "",
      cbCy:
        common.SetSelect(result?.cbCy) ||
        common.SetArrayToSelect(types.currency)[0],
      agcyCom:
        common.SetSelect(result?.agcyCom) ||
        common.SetArrayToSelect(types.commission)[0],
      promotionCode: result?.promotionCode || "",
      avgCourseFeeCy:
        common.SetSelect(result?.avgCourseFeeCy) ||
        common.SetArrayToSelect(types.currency)[0],
      avgCourseFee: result?.avgCourseFee,
      isView:
        common.SetSelect(result?.isView) ||
        common.SetArrayToSelect(types.role)[0],
      timesRanking: result?.timesRanking,
      stuSatisfaction: result?.stuSatisfaction,
      sortOrder: result?.sortOrder,
    });

    setFieldsB((prev) => ({
      ...prev,
      logo: result.logo,
      brochureDocs: result.brochureDocs,
      gallery: result.gallery,
    }));

    setMultiValueD({
      name: result.name,
    });

    // select
    setSelectRender({
      country: "yes",
      province: result.country ? "yes" : "no",
      city: result.province ? "yes" : "no",
    });
  };

  const getFields = () => {
    let res = {
      name: fieldsA.name,
      type: fieldsA?.type?.value || "",
      sinceYear: fieldsA.sinceYear,
      studentStrength: fieldsA.studentStrength,
      email: fieldsA.email,
      country: fieldsA.country?.value || "",
      province: fieldsA.province?.value || "",
      nationality: fieldsA?.nationality?.map((item) => item.value),
      city: fieldsA.city?.value || "",
      description: getLength("description") > 1 ? getHtml("description") : "",
      features: getLength("features") > 1 ? getHtml("features") : "",
      institutionDetail:
        getLength("institutionDetail") > 1 ? getHtml("institutionDetail") : "",
      coordinate: {
        lat: fieldsA?.coordinate ? fieldsA?.coordinate.lat : 20,
        lng: fieldsA?.coordinate ? fieldsA.coordinate.lng : 78,
      },
      location: fieldsA.location,
      logo: fieldsB.logo,
      brochureDocs: fieldsB.brochureDocs,
      gallery: fieldsB.gallery,
      visaPermit: fieldsA?.visaPermit ? fieldsA?.visaPermit : false,
      scholarship: fieldsA?.scholarship ? fieldsA?.scholarship : false,
      cab: fieldsA?.cab,
      agcy: fieldsA?.agcy,
      cbCy:
        fieldsA.cbCy?.value ||
        common.SetArrayToSelect(types.currency)[0]?.value,
      agcyCom:
        fieldsA.agcyCom?.value ||
        common.SetArrayToSelect(types.commission)[0]?.value,
      promotionCode: fieldsA.promotionCode || "",
      avgCourseFee: fieldsA?.avgCourseFee,
      avgCourseFeeCy:
        fieldsA.avgCourseFeeCy?.value ||
        common.SetArrayToSelect(types.role)[0]?.value,
      isView: fieldsA?.isView?.value,
      timesRanking: fieldsA?.timesRanking,
      stuSatisfaction: fieldsA?.stuSatisfaction,
      sortOrder: fieldsA.sortOrder,
    };
    return res;
  };

  // render
  return (
    <>
      <div className="title-wrap d-flex align-items-center">
        <img
          src={common.loadImg("back-icn.svg")}
          className="ed-cp"
          alt=""
          onClick={() => navigate("/university")}
        />
        <h1 className="page-title ps-4">
          {id ? "Edit" : "Add New"} University
        </h1>
      </div>
      {status !== "success" ? (
        <StatusBar status={status} />
      ) : (
        <Fragment>
          <div className="content-wrap form-page">
            <h2 className="form-title">University Info</h2>
            <div className="form-wrap">
              <div className="row">
                <div className="col-md-6 mb-4">
                  <label>University Name</label>
                  <input
                    ref={(el) => (myRefs.current.name = el)}
                    type="text"
                    name="name"
                    placeholder="Enter university name"
                    className="form-control"
                    value={fieldsA.name}
                    onChange={(e) => {
                      handleChange(e);
                      handleChangeD(e);
                    }}
                  />
                  {submit === "publish" && (
                    <p className="error-txt">{errors.name}</p>
                  )}
                  {submit === "draft" && (
                    <p className="error-txt">{errorsD.name}</p>
                  )}
                </div>
                <div className="col-md-6 mb-4">
                  <label>Since</label>
                  <input
                    type="text"
                    name="sinceYear"
                    value={fieldsA.sinceYear}
                    placeholder="Eg: 1970"
                    className="form-control"
                    onChange={handleChange}
                  />
                  {submit === "publish" && (
                    <p className="error-txt">{errors.sinceYear}</p>
                  )}
                </div>
                <div className="col-md-6 mb-4">
                  <label>No.of Students</label>
                  <input
                    type="text"
                    name="studentStrength"
                    value={fieldsA.studentStrength}
                    placeholder="Eg: 30,000 - 40,000"
                    className="form-control"
                    onChange={handleChange}
                  />
                  {submit === "publish" && (
                    <p className="error-txt">{errors.studentStrength}</p>
                  )}
                </div>
                <div className="col-md-6 mb-4">
                  <label>Email</label>
                  <input
                    type="email"
                    name="email"
                    value={fieldsA.email}
                    placeholder="Enter the email address"
                    className="form-control"
                    onChange={handleChange}
                  />
                  {submit === "publish" && (
                    <p className="error-txt">{errors.email}</p>
                  )}
                </div>
                <div className="col-md-6 mb-4">
                  <label>University Type</label>
                  <Select
                    className="custom-select-box"
                    value={fieldsA.type}
                    options={types.UniversityType}
                    isClearable={true}
                    onChange={(data) => {
                      setMultiValue({
                        type: data,
                      });
                    }}
                  />
                  {submit === "publish" && (
                    <p className="error-txt">{errors?.type}</p>
                  )}
                </div>
                <div className="col-md-6 mb-4">
                  <label>Country</label>
                  <FieldSelect
                    api="common_country"
                    query="?all=show"
                    get="name"
                    set="code"
                    value={fieldsA.country}
                    placeholder="Please select the country"
                    render={selectRender.country}
                    clearable={true}
                    finder={false}
                    multi={false}
                    onSelect={(data) => {
                      setMultiValue({
                        country: data,
                        province: null,
                        city: null,
                      });
                      setSelectRender((prev) => ({
                        ...prev,
                        province: data === null ? "empty" : new Date(),
                        city: "empty",
                      }));
                    }}
                  />
                  {submit === "publish" && (
                    <p className="error-txt">{errors.country}</p>
                  )}
                </div>
                <div className="col-md-6 mb-4">
                  <label>Province</label>
                  <FieldSelect
                    api="common_state"
                    query={`/${fieldsA?.country?.value}?&offset=1&limit=1000`}
                    get="name"
                    set="_id"
                    value={fieldsA.province}
                    placeholder="Please select the province"
                    render={selectRender.province}
                    clearable={true}
                    finder={false}
                    multi={false}
                    onSelect={(data) => {
                      setMultiValue({
                        province: data,
                        city: null,
                      });
                      setSelectRender((prev) => ({
                        ...prev,
                        city: data === null ? "empty" : new Date(),
                      }));
                    }}
                  />
                  {submit === "publish" && (
                    <p className="error-txt">{errors.province}</p>
                  )}
                </div>
                <div className="col-md-6 mb-4">
                  <label>City</label>
                  <FieldSelect
                    api="common_city"
                    query={`/${fieldsA?.country?.value}/${fieldsA?.province?.value}?&offset=1&limit=1000`}
                    get="name"
                    set="_id"
                    value={fieldsA.city}
                    placeholder="Please select the city"
                    render={selectRender.city}
                    clearable={true}
                    finder={false}
                    multi={false}
                    onSelect={(data) => {
                      setMultiValue({
                        city: data,
                      });
                    }}
                  />
                  {submit === "publish" && (
                    <p className="error-txt">{errors.city}</p>
                  )}
                </div>
                <div className="col-md-6 mb-4">
                  <label>Restricted Nationality</label>
                  <FieldSelect
                    api="common_country"
                    query="?all=show"
                    get="nationality"
                    set="code"
                    value={fieldsA.nationality}
                    placeholder="Please select restricted Nationality"
                    render={selectRender.nationality}
                    clearable={true}
                    finder={false}
                    multi={true}
                    onSelect={(data) => {
                      setMultiValue({
                        nationality: data,
                      });
                    }}
                  />
                  {submit === "publish" && (
                    <p className="error-txt">{errors.nationality}</p>
                  )}
                </div>
                <div className="col-md-6 mb-4">
                  <label>Cash Back</label>
                  <div className="d-flex">
                    <div className="col-md-7">
                      <input
                        type="text"
                        name="cab"
                        placeholder="Cash Back"
                        className="form-control select-left"
                        value={fieldsA?.cab}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-5">
                      <Select
                        className="custom-select-box"
                        value={fieldsA.cbCy}
                        options={common.SetArrayToSelect(types.currency)}
                        isClearable={true}
                        onChange={(data) => {
                          setMultiValue({
                            cbCy: data,
                          });
                        }}
                      />
                    </div>
                  </div>
                  {/* {submit === "publish" && (
                      <p className="error-txt">
                        {errorsA.cab || errorsA.cbCy}
                      </p>
                    )} */}
                </div>
                <div className="col-md-6 mb-4">
                  <label>Agent Commission</label>
                  <div className="d-flex">
                    <div className="col-md-7">
                      <input
                        type="text"
                        name="agcy"
                        placeholder="Agent Commission"
                        className="form-control select-left"
                        value={fieldsA?.agcy}
                        maxLength={
                          fieldsA?.agcyCom?.value === "Percentage" ? 3 : ""
                        }
                        onChange={(e) => {
                          if (fieldsA?.agcyCom?.value === "Percentage") {
                            if (e.target.value <= 100) {
                              handleChange(e);
                            }
                          } else {
                            handleChange(e);
                          }
                        }}
                      />
                    </div>
                    <div className="col-md-5">
                      <Select
                        className="custom-select-box"
                        value={fieldsA.agcyCom}
                        options={common.SetArrayToSelect(types.commission)}
                        isClearable={true}
                        onChange={(data) => {
                          if (data?.value === "Percentage") {
                            setMultiValue({
                              agcy: "",
                              agcyCom: data,
                            });
                          } else {
                            setMultiValue({
                              agcyCom: data,
                            });
                          }
                        }}
                      />
                    </div>
                  </div>
                  {/* {submit === "publish" && (
                      <p className="error-txt">
                        {errorsA.cab || errorsA.cbCy}
                      </p>
                    )} */}
                </div>

                <div className="col-md-6 mb-4">
                  <label>Promotion Code</label>
                  <input
                    type="text"
                    name="promotionCode"
                    value={fieldsA.promotionCode}
                    placeholder="Enter promotion code to filter"
                    className="form-control"
                    onChange={handleChange}
                  />
                </div>

                <div className="col-md-6 mb-4">
                  <label>Display Order</label>
                  <input
                    type="text"
                    name="sortOrder"
                    value={fieldsA.sortOrder}
                    className="form-control"
                    onChange={handleChange}
                  />
                  {submit === "publish" && (
                    <p className="error-txt">{errors.sortOrder}</p>
                  )}
                </div>

                <div className="col-md-6 mb-4">
                  <label>Avg Course Fee</label>
                  <div className="d-flex">
                    <div className="col-md-7">
                      <input
                        type="text"
                        name="avgCourseFee"
                        placeholder="Avg Course Fee"
                        className="form-control select-left"
                        value={fieldsA?.avgCourseFee}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-5">
                      <Select
                        className="custom-select-box"
                        value={fieldsA.avgCourseFeeCy}
                        options={common.SetArrayToSelect(types.currency)}
                        isClearable={true}
                        onChange={(data) => {
                          setMultiValue({
                            avgCourseFeeCy: data,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mb-4">
                  <label>Times Ranking</label>
                  <input
                    type="text"
                    name="timesRanking"
                    value={fieldsA.timesRanking}
                    placeholder="Enter times ranking"
                    className="form-control"
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6 mb-4">
                  <label>Accees Role</label>
                  <Select
                    className="custom-select-box"
                    value={fieldsA.isView}
                    options={common.SetArrayToSelect(types.role)}
                    isClearable={true}
                    onChange={(data) => {
                      setMultiValue({
                        isView: data,
                      });
                    }}
                  />
                </div>
                <div className="col-md-6 mb-4">
                  <label>Student Satisfaction</label>
                  <input
                    type="text"
                    name="stuSatisfaction"
                    value={fieldsA.stuSatisfaction}
                    placeholder="Enter student satisfication percentage"
                    className="form-control"
                    onChange={handleChange}
                  />
                </div>

                <div className="col-md-6 mb-4">
                  {/* <label>Visa Permit</label>
                  <input
                    type="checkbox"
                    name="visaPermit"
                    value={true}
                    checked={fieldsA?.visaPermit}
                    className="form-control"
                    onChange={handleChange}
                  /> */}
                  <label className="role-checkbox-wrap">
                    <input
                      type="checkbox"
                      name="visaPermit"
                      value={true}
                      checked={fieldsA?.visaPermit}
                      onChange={handleChange}
                    />
                    <span className="checkmark"></span>
                    Visa Permit
                  </label>
                </div>
                <div className="col-md-6 mb-4">
                  <label className="role-checkbox-wrap">
                    <input
                      type="checkbox"
                      name="scholarship"
                      value={true}
                      checked={fieldsA?.scholarship}
                      onChange={handleChange}
                    />
                    <span className="checkmark"></span>
                    Scholarship
                  </label>
                </div>
              </div>
            </div>
            <h2 className="form-title">Attachment files</h2>
            <div className="form-wrap">
              <div className="row">
                <div className="col-md-6 mb-4">
                  <label>Emblem of University</label>
                  <input
                    type="file"
                    name="logoFile"
                    className="form-control"
                    multiple={false}
                    value={""}
                    onChange={
                      (e) =>
                        uploadFileOnChange([...e?.target?.files], "logoFile")
                      // onFileChange(e)
                    }
                  />
                  {/* already uploaded */}
                  {fieldsB.logo && fieldsB.logoFile.length === 0 && (
                    <div className="d-flex align-items-center justify-content-between pt-3">
                      <p
                        className="agt-proof-file mb-0 et-cp"
                        onClick={() =>
                          setGalleryImage(common.cloudImg(fieldsB.logo))
                        }
                      >
                        <Link to="">{common.fileName(fieldsB.logo)}</Link>
                      </p>
                      <img
                        src={common.loadImg("iconly-dark-delete.svg")}
                        className="et-cp"
                        onClick={() =>
                          setFieldsB((prev) => ({ ...prev, logo: "" }))
                        }
                      />
                    </div>
                  )}
                  {/* newly uploaded */}
                  {fieldsB.logoFile.map((cI, cK) => (
                    <div
                      className="d-flex align-items-center justify-content-between pt-3"
                      key={cK}
                    >
                      <p
                        className="agt-proof-file mb-0 et-cp"
                        onClick={() => setGalleryImage(URL.createObjectURL(cI))}
                      >
                        <Link to="">{cI.name}</Link>
                      </p>
                      <img
                        src={common.loadImg("Spinner-1s-38px.gif")}
                        className="et-cp"
                      />
                    </div>
                  ))}
                  {/* error */}
                  {submit === "publish" &&
                    fieldsB.logo === "" &&
                    fieldsB.logoFile.length === 0 && (
                      <p className="error-txt">Please upload the logo</p>
                    )}
                </div>
                <div className="col-md-6 mb-4">
                  <label>Brochure of University</label>
                  <input
                    type="file"
                    name="brochureDocsFile"
                    className="form-control"
                    multiple={false}
                    value={""}
                    onChange={(e) =>
                      uploadFileOnChange(
                        [...e?.target?.files],
                        "brochureDocsFile"
                      )
                    }
                  />

                  {/* already uploaded */}
                  {fieldsB.brochureDocs &&
                    fieldsB.brochureDocsFile.length === 0 && (
                      <div className="d-flex align-items-center justify-content-between pt-3">
                        <p className="agt-proof-file mb-0">
                          <Link
                            to=""
                            onClick={() =>
                              common.openCloudUrl(fieldsB.brochureDocs)
                            }
                          >
                            {common.fileName(fieldsB.brochureDocs)}
                          </Link>
                        </p>
                        <img
                          src={common.loadImg("iconly-dark-delete.svg")}
                          className="et-cp"
                          onClick={() =>
                            setFieldsB((prev) => ({
                              ...prev,
                              brochureDocs: "",
                            }))
                          }
                        />
                      </div>
                    )}
                  {/* newly uploaded */}
                  {fieldsB.brochureDocsFile.map((cI, cK) => (
                    <div
                      className="d-flex align-items-center justify-content-between pt-3"
                      key={cK}
                    >
                      <p
                        className="agt-proof-file mb-0"
                        onClick={() => common.openObjectUrl(cI)}
                      >
                        <Link to="">{cI.name}</Link>
                      </p>
                      <img
                        src={common.loadImg("Spinner-1s-38px.gif")}
                        className="et-cp"
                      />
                    </div>
                  ))}
                  {/* error */}
                  {submit === "publish" &&
                    fieldsB.brochureDocs === "" &&
                    fieldsB.brochureDocsFile.length === 0 && (
                      <p className="error-txt">Please upload the brouchure</p>
                    )}
                </div>
                <div className="col-12 mb-4">
                  <label>Campus Photo</label>
                  <FileUpload
                    multiple={true}
                    onSuccess={(files) => {
                      // setFieldsB((prev) => {
                      //   let nData = { ...prev };
                      //   nData.galleryFile.push(...files.acceptedFiles);
                      //   return nData;
                      // });
                      uploadFileOnChange(files?.acceptedFiles, "gallery");
                    }}
                  />
                  {["gallery", "galleryFile"].map((i, k) =>
                    fieldsB[i].map((cI, cK) => (
                      <div
                        className="d-flex align-items-center justify-content-between pt-3"
                        key={cK}
                      >
                        <p
                          className="agt-proof-file mb-0"
                          onClick={() => {
                            setGalleryImage(
                              i == "gallery"
                                ? common.cloudImg(cI)
                                : URL.createObjectURL(cI)
                            );
                          }}
                        >
                          <Link to="">
                            {i == "gallery"
                              ? common.fileName(cI)
                              : `${cI.name}`}
                          </Link>
                        </p>
                        {i == "gallery" ? (
                          <img
                            src={common.loadImg("iconly-dark-delete.svg")}
                            className="et-cp"
                            onClick={() => deleteFile(cK, i)}
                          />
                        ) : (
                          <img
                            src={common.loadImg("Spinner-1s-38px.gif")}
                            className="et-cp"
                          />
                        )}
                      </div>
                    ))
                  )}
                  {submit === "publish" &&
                    fieldsB.gallery.length === 0 &&
                    fieldsB.galleryFile.length === 0 && (
                      <p className="error-txt">Please upload the gallery</p>
                    )}
                </div>
              </div>
            </div>
            <h2 className="form-title">About University</h2>
            <div className="form-wrap">
              <div className="row">
                <div className="col-12 mb-4">
                  <label>Brief of University</label>
                  <Editor
                    editorState={fieldsA.description}
                    onEditorStateChange={(e) =>
                      setMultiValue({ description: e })
                    }
                    wrapperClassName="wrapper-class"
                    editorClassName="editor-class"
                    toolbarClassName="toolbar-class"
                    toolbar={{
                      options: ["blockType", "list"],
                      blockType: {
                        inDropdown: true,
                        options: ["H1", "H2", "H3", "H4", "H5", "H6"],
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                      },
                      list: {
                        inDropdown: false,
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                        options: ["unordered", "ordered"],
                      },
                    }}
                  />
                  {submit === "publish" && getLength("description") <= 200 && (
                    <p className="error-txt">Content length too low</p>
                  )}
                </div>
              </div>
            </div>
            <h2 className="form-title">Features</h2>
            <div className="form-wrap">
              <div className="row">
                <div className="col-12 mb-4">
                  <label>Enter the Features</label>
                  <Editor
                    editorState={fieldsA.features}
                    onEditorStateChange={(e) => setMultiValue({ features: e })}
                    wrapperClassName="wrapper-class"
                    editorClassName="editor-class"
                    toolbarClassName="toolbar-class"
                    toolbar={{
                      options: ["list"],
                      list: {
                        inDropdown: false,
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                        options: ["unordered", "ordered"],
                      },
                    }}
                  />
                </div>
              </div>
            </div>
            <h2 className="form-title">Institution Details</h2>
            <div className="form-wrap">
              <div className="row">
                <div className="col-12 mb-4">
                  <label>Enter the Institution details</label>
                  <Editor
                    editorState={fieldsA.institutionDetail}
                    onEditorStateChange={(e) =>
                      setMultiValue({ institutionDetail: e })
                    }
                    wrapperClassName="wrapper-class"
                    editorClassName="editor-class"
                    toolbarClassName="toolbar-class"
                    toolbar={{
                      options: ["blockType", "list"],
                      blockType: {
                        inDropdown: true,
                        options: ["H1", "H2", "H3", "H4", "H5", "H6"],
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                      },
                      list: {
                        inDropdown: false,
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                        options: ["unordered", "ordered"],
                      },
                    }}
                  />
                  {submit === "publish" &&
                    getLength("institutionDetail") <= 50 && (
                      <p className="error-txt">Content length too low</p>
                    )}
                </div>
              </div>
            </div>
            <h2 className="form-title">Location Details</h2>
            <div className="form-wrap">
              <div className="row">
                <div className="col-12 mb-4">
                  <label>University Address</label>
                  <input
                    type="text"
                    name="location"
                    className="form-control"
                    placeholder="Enter Address"
                    value={fieldsA.location}
                    onChange={handleChange}
                  />
                  <p className="error-txt">{errors.location}</p>
                </div>
                <div className="col-12">
                  <label>Location</label>
                  <GoogleMaps
                    coordinate={{
                      lat: fieldsA?.coordinate
                        ? fieldsA?.coordinate.lat
                        : 51.7459809,
                      lng: fieldsA?.coordinate
                        ? fieldsA.coordinate.lng
                        : 0.2249571,
                    }}
                    zoom={5}
                    search={true}
                    dragMarker={true}
                    clickMarker={true}
                    markerText=""
                    onSuccess={(data) => setMultiValue({ coordinate: data })}
                  />
                  <p className="error-txt">{errors.coordinate}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="inner-footer">
            <button
              className="btn ed-btn-default ed-btn-cancel me-3"
              disabled={loading !== null}
              onClick={() => navigate("/university")}
            >
              Cancel
            </button>
            {!fieldsA.isPublished && (
              <button
                className="btn ed-btn-default ed-btn-save-dt me-3"
                disabled={loading !== null || disabled}
                onClick={() => onSubmit("draft")}
              >
                {loading === "draft" ? "Processing..." : "Save & Draft"}
              </button>
            )}
            <button
              className="btn ed-btn-default ed-btn-save-pub"
              onClick={() => onSubmit("publish")}
              disabled={loading !== null || disabled}
            >
              {loading === "publish" ? "Publishing..." : "Save & Publish"}
            </button>
          </div>
          {galleryImage && (
            <Lightbox
              mainSrc={galleryImage}
              onCloseRequest={() => setGalleryImage(null)}
            />
          )}
        </Fragment>
      )}
    </>
  );
}

export default Form;
