import { BrowserRouter as Router } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
// includes
import { config } from "helpers";
// layouts
import InnerLayout from "layouts/innerLayout";
// auth
import SignIn from "pages/auth/signin";
// home
import Home from "pages/home/home";
// agent
import AgentList from "pages/agent/list";
import AgentView from "pages/agent/view";
import AgentForm from "pages/agent/form";
// student
import StudentList from "pages/student/list";
import StudentView from "pages/student/view";
import StudentForm from "pages/student/form";
import StudentEdit from "pages/student/edit";
// application
import ApplicationList from "pages/application/list";
import ApplicationView from "pages/application/view";
// university
import UniversityForm from "pages/university/form";
import UniversityView from "pages/university/view";
import UniversityList from "pages/university/list";
// course
import CourseForm from "pages/course/form";
import CourseView from "pages/course/view";
import CourseList from "pages/course/list";
// subject
import SubjectList from "pages/subject/list";
// users
import UsersForm from "pages/users/form";
import UsersView from "pages/users/list";
import UsersList from "pages/users/list";
// users
import PromoForm from "pages/promo/form";
import PromoList from "pages/promo/list";
// setup
import SetupForm from "pages/setup/form";
// profile
import Profile from "pages/profile/profile";
import ForgotPassword from "pages/auth/forgotPassword";
import OtpVerification from "pages/auth/otpVerification";
import ResetPassword from "pages/auth/resetPassword";
// member
import MemberList from "pages/members/list";
import MemberView from "pages/members/view";
import PermissionList from "pages/permission/list";
import PermissionForm from "pages/permission/form";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<SignIn />} />
        <Route path="/password/forgot" element={<ForgotPassword />} />
        <Route path="/password/reset" element={<ResetPassword />} />
        <Route path="/otp/verify" element={<OtpVerification />} />
        <Route path="/" element={<InnerLayout />}>
          {/* home */}
          <Route path="/home" element={<Home />} />
          {/* agent */}
          <Route path="/agent/add" element={<AgentForm />} />
          <Route path="/agent" element={<AgentList />} />
          <Route path="/agent/view/:id" element={<AgentView />} />
          {/* student */}
          <Route path="/student/add" element={<StudentForm />} />
          <Route path="/student" element={<StudentList />} />
          <Route path="/student/view/:id" element={<StudentView />} />
          <Route path="student/edit/:id/:type/:from" element={<StudentEdit />} />
          {/* application */}
          <Route path="/application" element={<ApplicationList />} />
          <Route path="/application/view/:id" element={<ApplicationView />} />
          {/* university */}
          <Route path="/university" element={<UniversityList />} />
          <Route path="/university/add" element={<UniversityForm />} />
          <Route path="/university/edit/:id" element={<UniversityForm />} />
          <Route path="/university/view/:id" element={<UniversityView />} />
          {/* course */}
          <Route path="/course" element={<CourseList />} />
          <Route path="/course/add" element={<CourseForm />} />
          <Route path="/course/edit/:id" element={<CourseForm />} />
          <Route path="/course/view/:id" element={<CourseView />} />
          {/* subject */}
          <Route path="/subject" element={<SubjectList />} />
          {/* promo code */}
          <Route path="/promo" element={<PromoList />} />
          <Route path="/promo/add" element={<PromoForm />} />
          <Route path="/promo/edit/:id" element={<PromoForm />} />
          {/* users */}
          <Route path="/users" element={<UsersList />} />
          <Route path="/users/add" element={<UsersForm />} />
          <Route path="/users/edit/:id" element={<UsersForm />} />
          <Route path="/users/view/:id" element={<UsersView />} />
          {/* setup */}
          <Route path="/setup" element={<SetupForm />} />
          {/* profile */}
          <Route path="/profile" element={<Profile />} />
          {/* subAgent */}
          <Route path="/member" element={<MemberList />} />
          <Route path="/member/:id" element={<MemberList />} />
          <Route path="/member/view/:id" element={<MemberView />} />
          <Route path="/permissions" element={<PermissionList />} />
          <Route path="/permissions/add" element={<PermissionForm/>} />
          <Route path="/permissions/edit/:id" element={<PermissionForm/>} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
