import ModalConfirm from "elements/modalConfirm";
import { common } from "helpers";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// includes
import AgTable from "elements/AgTable";
import { api } from "helpers";

function CourseList() {
  // init
  const navigate = useNavigate();
  const [reload, setReload] = useState(new Date());
  const [deleteData, setDeleteData] = useState(null);
  const [loading, setLoading] = useState(false);

  let oldFilter = sessionStorage.getItem("course")
    ? common.filterRetrieving(JSON.parse(sessionStorage.getItem("course")))
    : {
        universityMulti: { key: "university", value: [] },
        published: { key: "isPublished", value: { label: "Yes", value: true } },
        feesSlider: { key: "tuitionFee", value: [1, 10000000] },
        dateA: { key: "startDate", value: "", title: "Created Date From" },
        dateB: { key: "endDate", value: "", title: "Created Date To" },
      };
  const [filterData, setFilterData] = useState(oldFilter);

  // state
  const [permission] = useState(common.getUserPermission());
  // effect
  useEffect(() => {
    if (!permission.includes("cor")) {
      navigate("/profile");
    }
  }, []);
  // function
  const callAction = (data) => {
    if (data.type === "Add") {
      navigate("/course/add");
    } else if (data.type === "Edit") {
      navigate("/course/edit/" + data.item._id);
    } else if (data.type === "View") {
      navigate("/course/view/" + data.item._id);
    } else if (data.type === "Delete") {
      setDeleteData(data.item);
    }
  };

  //api
  const DeleteRecord = (item) => {
    setLoading(true);
    let data = {
      url: "course",
      method: "DELETE",
      query: `/${item._id}`,
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        common.notify("S", "Record has been removed");
        setDeleteData(null);
        setReload(new Date());
        setLoading(false);
      }
    });
  };

  return (
    permission.includes("cor") && (
      <>
        <AgTable
          module="adminCourse"
          reload={reload}
          filterData={filterData}
          actionPermission={common.accessTable(permission, [
            "coc",
            "cou",
            "cor",
            "cod",
          ])}
          filterSession={"course"}
          onAction={(data) => callAction(data)}
        />
        {deleteData !== null && (
          <ModalConfirm
            loading={loading}
            onYes={() => DeleteRecord(deleteData)}
            onNo={() => setDeleteData(null)}
          />
        )}
      </>
    )
  );
}

export default CourseList;
