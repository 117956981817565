import React, { Fragment, useEffect, useState } from "react";
// includes
import { common } from "helpers";

function StudentDocs(props) {
  // props
  const { data } = props;
  // state
  const [init] = useState(true);
  const [docs, setDocs] = useState({});
  const [docsA] = useState({
    passportDocs: data.passportDocs,
    visaDocs: data.visaDocs,
  });

  // effect
  useEffect(() => {
    setDocs(reParse(data));
  }, []);

  // functions
  const reParse = (data) => {
    let res = {
      college: {
        title: "Education Documents",
        data: data.college,
        docs: data.college.filter((i) => i.docs.length > 0).length,
        docsTitle: "institutionName",
        selectInp: true,
        selectTitle: "College",
        selectData: data.college.map((i, k) => ({
          label: i.institutionName,
          value: k,
        })),
      },
      exam: {
        title: "Test Score Documents",
        data: data.exam,
        docs: data.exam.filter((i) => i.docs.length > 0).length,
        docsTitle: "examType",
        selectInp: true,
        selectTitle: "Course",
        selectData: data.exam.map((i, k) => ({
          label: i.examType,
          value: k,
        })),
      },
      workExp: {
        title: "Work Experience Documents",
        data: data.workExp,
        docs: data.workExp.filter((i) => i.docs.length > 0).length,
        docsTitle: "companyName",
        selectInp: true,
        selectTitle: "Company",
        selectData: data.workExp.map((i, k) => ({
          label: i.companyName,
          value: k,
        })),
      },
      extraCurricular: {
        title: "Extracurricular Activity Documents",
        data: data.extraCurricular,
        docs: data.extraCurricular.filter((i) => i.docs.length > 0).length,
        docsTitle: "",
        selectInp: false,
        selectTitle: "",
        selectData: [],
      },
      otherActivity: {
        title: "Other Documents",
        data: data.otherActivity,
        docs: data.otherActivity.filter((i) => i.docs.length > 0).length,
        docsTitle: "",
        selectInp: false,
        selectTitle: "",
        selectData: [],
      },
    };
    return res;
  };

  // render
  return (
    <Fragment>
      {init && (
        <Fragment>
          <div className="et-view-content-wrap">
            <div className="register-step-header-general">
              <p className="register-step-title mb-0">Document Attach</p>
            </div>
            {Object.keys(docs).map((i, k) => {
              let nI = docs[i];
              return (
                <Fragment key={k}>
                  <div className="et-view-title-wrap d-flex justify-content-between align-items-center">
                    <h3 className="et-view-secondary-title">{nI.title}</h3>
                  </div>
                  {nI?.docs === 0 ? (
                    <div className="et-vc--no-info d-flex justify-content-between align-items-center et-view-border-bottom">
                      <div className="et-vc-left">
                        <p className="et-vc-label">No document uploaded yet.</p>
                        <p className="et-vc-info-required">{nI.title}</p>
                      </div>
                    </div>
                  ) : (
                    <Fragment>
                      <div className="et-vc-info et-view-border-bottom">
                        <ul className="et-vc-list row">
                          {/* Already added */}
                          {nI.data.map((iA, kA) => {
                            return (
                              <li
                                className="col-md-6 et-view-border-right et-view-border-bottom"
                                key={kA}
                              >
                                <div className="et-list-inner">
                                  {nI.selectInp ? (
                                    <p className="et-field-name">
                                      {iA[nI.docsTitle]}
                                    </p>
                                  ) : (
                                    <p className="et-field-name">{iA.title}</p>
                                  )}
                                  {iA.docs.length ? (
                                    iA.docs.map((iB, kB) => (
                                      <div key={kB} className="row mb-3">
                                        <div className="et-field-value col-md-9">
                                          <span
                                            className="et-link"
                                            onClick={() =>
                                              common.openCloudUrl(iB)
                                            }
                                          >
                                            {common.fileName(iB)}
                                          </span>
                                        </div>
                                      </div>
                                    ))
                                  ) : (
                                    <div className="row mb-3">
                                      <div className="et-field-value col-md-9">
                                        No files uploaded yet
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </Fragment>
                  )}
                </Fragment>
              );
            })}
            <div className="et-view-title-wrap d-flex justify-content-between align-items-center">
              <h3 className="et-view-secondary-title">Travel Documents</h3>
            </div>
            <div className="et-vc-info et-view-border-bottom">
              <ul className="et-vc-list row">
                {["passportDocs"].map((i, k) => (
                  <li
                    className="col-md-6 et-view-border-right et-view-border-bottom"
                    key={k}
                  >
                    <div className="et-list-inner">
                      <p className="et-field-name">Copy of your passport</p>
                      {docsA[i]?.length > 0 ? (
                        docsA[i]?.map((dItem, dKey) => (
                          <p
                            className="et-field-value et-link"
                            key={dKey}
                            onClick={() => common.openCloudUrl(dItem)}
                          >
                            {common.fileName(dItem)}
                          </p>
                        ))
                      ) : (
                        <Fragment>
                          <div className="row mb-3">
                            <div className="et-field-value col-md-9">
                              No file uploaded yet
                            </div>
                          </div>
                        </Fragment>
                      )}
                    </div>
                  </li>
                ))}
                {["visaDocs"].map((i, k) => (
                  <li
                    className="col-md-6 et-view-border-right et-view-border-bottom"
                    key={k}
                  >
                    <div className="et-list-inner">
                      <p className="et-field-name">Copy of your visa</p>
                      {docsA[i] ? (
                        <p
                          className="et-field-value et-link"
                          onClick={() => common.openCloudUrl(docsA[i])}
                        >
                          {common.fileName(docsA[i])}
                        </p>
                      ) : (
                        <Fragment>
                          <div className="row mb-3">
                            <div className="et-field-value col-md-9">
                              No file uploaded yet
                            </div>
                          </div>
                        </Fragment>
                      )}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
}

export default StudentDocs;
