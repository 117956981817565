import moment from "moment";
import { Fragment, useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import DateRangePicker from "react-bootstrap-daterangepicker";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  Link,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import Select from "react-select";

// includes
import { api, common, config, types } from "helpers";
// pages
import FileUpload from "elements/fileUpload";
import ModalConfirm from "elements/modalConfirm";
import StatusBar from "elements/statusBar";
// files
import studentFields from "assets/files/studentFields.json";

function StudentForm(props) {
  const workDocRef = useRef(null);

  const scrollToBottom = () => {
    workDocRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(() => {
    scrollToBottom();
  }, []);

  const navigate = useNavigate();
  // props
  const { studentId, formType, formModule, formModal, formIndex, formAddNew } =
    props;
  // const [outletAction, authInfo, userInfo, userInfoStatus] = useOutletContext();
  const { id, type } = useParams();
  const nav = {
    personal_address: "education_college",
    education_college: "background",
    background: "exam",
    exam: "workExp",
    workExp: "document",
    document: "document",
  };

  // state
  const [init, setInit] = useState(false);
  const [status, setStatus] = useState("process");
  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({});
  const [errorServer, setErrorServer] = useState(null);
  const [docs, setDocs] = useState({});
  const [submit, setSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [confirm, setConfirm] = useState(null);
  const [docsModal, setDocsModal] = useState(null);
  const [examError, setExamError] = useState([]);
  const [data, setData] = useState({
    countryList: [],
    countrySelect: [],
  });
  const [selectRender, setSelectRender] = useState({
    country: "yes",
    province: "yes",
    city: "no",
  });
  const [authData] = useState(common.getAuth());

  // effect
  useEffect(() => {
    initData();
  }, []);

  useEffect(() => {
    if (init && submit) {
      let moduleInfo = String(formModule);
      validate(moduleInfo.split("_"));
    }
  }, [fields]);

  // api
  const initData = () => {
    getCountryList();
    studentInfo();
  };

  // api
  const studentInfo = () => {
    let data = {
      url: "student",
      method: "GET",
      query: `/${id}`,
    };
    api.call(data, (res) => {
      console.log("========res", res);
      if (res.status === 200) {
        let result = reParse(res.data);
        console.log("========result", result);
        setFields(result.fields);
        setErrors(result.errors);
        setDocs(result.document);
        setStatus("success");
        setInit(true);
      } else {
        setStatus("error");
      }
    });
  };

  const agentStudentInfo = () => {
    let data = {
      url: "agent_student",
      query: `/${studentId}`,
      method: "GET",
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        let result = reParse(res.data);
        setFields(result.fields);
        setErrors(result.errors);
        setDocs(result.document);
        setStatus("success");
        setInit(true);
      } else {
        setStatus("error");
      }
    });
  };

  const applicationInfo = () => {
    let data = {
      url: "application_profile",
      query: `/${studentId}`,
      method: "GET",
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        let result = reParse(res.data);
        setFields(result.fields);
        setErrors(result.errors);
        setDocs(result.document);
        setStatus("success");
        setInit(true);
      } else {
        setStatus("error");
      }
    });
  };

  const getCountryList = () => {
    let data = {
      url: "common_country",
      query: "?all=show",
      method: "GET",
    };
    api.call(data, (res) => {
      if (res.status == 200) {
        let result = res.data.data;
        setData((prev) => ({
          ...prev,
          countryList: result,
          countrySelect: common.SetMultiSelect(result, "name", "code"),
          nationality: common.SetMultiSelect(result, "nationality", "code"),
        }));
      }
    });
  };

  // handler
  const onInputChange = (e, sub, k) => {
    const { name, value } = e.target;
    if (sub) {
      let f = common.reParse(fields);
      f[sub][k][name] = value;
      setFields(f);
    } else {
      setFields((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const onNumberInputChange = (e, sub, k) => {
    const { name, value } = e.target;
    if (sub) {
      let f = common.reParse(fields);
      f[sub][k][name] = common.onlyNumbers(value);
      setFields(f);
    } else {
      setFields((prev) => ({
        ...prev,
        [name]: common.onlyNumbers(value),
      }));
    }
  };

  const onInputChangeScore = (e, k) => {
    const { name, value } = e.target;
    if (
      ["IELTS", "TOEFL", "IELTS UKVI"].includes(
        fields?.exam[k]?.examType?.value
      )
    ) {
      let process = true;
      if (fields?.exam[k]?.examType?.value === "IELTS" && Number(value) > 9) {
        process = false;
      } else if (
        fields?.exam[k]?.examType?.value === "TOEFL" &&
        Number(value) > 30
      ) {
        process = false;
      } else if (
        fields?.exam[k]?.examType?.value === "IELTS UKVI" &&
        Number(value) > 9
      ) {
        process = false;
      }
      //setExamError
      if (process) {
        let f = common.reParse(fields);
        let val = common.onlyFloat(value);
        f.exam[k][name] = val;
        if (fields?.exam[k]?.examType?.value === "TOEFL") {
          f.exam[k].testScore =
            Number(f.exam[k].listening) +
            Number(f.exam[k].reading) +
            Number(f.exam[k].writing) +
            Number(f.exam[k].speaking);
          setFields(f);
        } else {
          let total =
            (Number(f.exam[k].listening) +
              Number(f.exam[k].reading) +
              Number(f.exam[k].writing) +
              Number(f.exam[k].speaking)) /
            4;

          f.exam[k].testScore = total.toFixed(2);
          setFields(f);
        }
      }
    } else {
    }
  };

  const onDatePickerChange = (name, value, sub, k) => {
    if (sub) {
      let f = common.reParse(fields);
      f[sub][k][name] =
        value === "" ? "" : moment(value).format(config.elements.dp.A);
      setFields(f);
    } else {
      setFields((prev) => ({
        ...prev,
        [name]: value === "" ? "" : moment(value).format(config.elements.dp.A),
      }));
    }
  };

  const onSelectChange = (name, value, sub, k) => {
    if (sub) {
      let f = common.reParse(fields);
      f[sub][k][name] = value;
      if (sub === "exam") {
        f.exam[k].testScore = "";
        ["listening", "reading", "writing", "speaking", "dateOfIssue"].map(
          (item) => (f.exam[k][item] = "")
        );
        ["docs"].map((item) => (f.exam[k][item] = []));
      }
      setFields(f);
    } else {
      setFields((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const uploadFile = async (arr) => {
    var formdata = new FormData();
    arr.map((i) => {
      formdata.append("file", i);
    });
    let data = {
      url: "common_upload",
      method: "POST",
      body: formdata,
      ssr: true,
      cType: 6,
    };
    let resultData = await api.call(data);
    return resultData;
  };

  // submit hander

  const onSubmit = async (step) => {
    setSubmit(true);
    let currentModule = String(formModule).split("_");

    let submit = validate(currentModule);

    if (submit) {
      setLoading(true);
      let error = "";
      let params = getFields(currentModule);

      // file upload
      if (
        [
          "college",
          "education_college",
          "exam",
          "exam_background",
          "workExp",
          "passportDocs",
        ].includes(formModule)
      ) {
        let docModule = String(formModule);

        if (docModule === "education_college") {
          docModule = "college";
        } else if (docModule === "exam_background") {
          docModule = "exam";
        }
        await Promise.all(
          docs[docModule].map(async (i, k) => {
            params[docModule][k].docs = params[docModule][k]?.docs?.filter(
              (item) => item.match(/live|dev|file/)
            );
            if (i.length > 0) {
              let result = await uploadFile(i);
              if (result.status !== 200) {
                error = result.data.error;
              } else {
                params[docModule][k].docs = [
                  ...params[docModule][k].docs,
                  ...result.data.link,
                ];
              }
            }
          })
        );
      }

      if (["personal"].includes(currentModule[0])) {
        if (docs["passportDocs"] && docs["passportDocs"].length) {
          let result = await uploadFile(docs["passportDocs"]);
          if (result.status !== 200) {
            error = result.data.error;
          } else {
            let getPrvFile = params["passportDocs"].filter((item) =>
              item.match(/live|dev|homeneu/)
            );
            params["passportDocs"] = [...getPrvFile, ...result.data.link];
          }
        }
      }

      if (error) {
        common.notify("E", error);
        setLoading(false);
      } else {
        let data = {
          url: "student",
          method: "PUT",
          query: `/${studentId}`,
          body: params,
        };

        api.call(data, (res) => {
          if (res.status === 200) {
            if (step !== 5) {
              navigate(`/student/edit/${id}/${nav[type]}/2`);
            } else {
              navigate(`/student`);
              props?.onSuccess(res.data._id);
              // if (authData.role === "student") {
              // outletAction("myProgress");
              // }
            }
          } else if (res.status === 500) {
            setErrorServer({ email: res.data.error });
          } else {
            common.notify("E", res.data.error);
          }
          setLoading(false);
        });
      }
    }
  };

  // validation
  const validate = (type) => {
    let isValid = true;
    let err = common.reParse(errors);
    type.map((tItem) => {
      if (["college", "exam", "workExp"].includes(tItem)) {
        fields[tItem].map((item, key) => {
          for (var cKey of Object.keys(studentFields[tItem])) {
            err[tItem][key][cKey] = "";
            if (
              studentFields[tItem][cKey].isReq != "" &&
              (item[cKey] == "" || item[cKey] == null)
            ) {
              err[tItem][key][cKey] = studentFields[tItem][cKey].isReq;
              isValid = false;
            }
          }
        });
      } else {
        for (var key of Object.keys(studentFields[tItem])) {
          err[key] = "";
          if (
            studentFields[tItem][key].isReq != "" &&
            (fields[key] == "" || fields[key] == null)
          ) {
            err[key] = studentFields[tItem][key].isReq;
            isValid = false;
          }
        }
      }

      // passport validation
      // if (tItem === "personal") {
      //   if (
      //     types.passportCountry.includes(fields.birth?.value) &&
      //     fields.passportNumber !== ""
      //   ) {
      //     if (
      //       !validator.isPassportNumber(
      //         fields.passportNumber,
      //         fields.birth.value
      //       )
      //     ) {
      //       err.passportNumber = "Invalid passport number";
      //       isValid = false;
      //     }
      //   }
      // }
    });

    setErrors(err);
    return isValid;
  };

  // support
  const studentAdd = () => {
    let result = reParse({});
    setFields(result.fields);
    setErrors(result.errors);
    setDocs(result.document);
    setStatus("success");
    setInit(true);
  };

  const reParse = (res) => {
    let resData = common.reParse(res);
    let formFields = common.reParse(studentFields);
    let document = {
      college: [],
      exam: [],
      workExp: [],
      passportDocs: [],
    };

    let result = {
      // personal
      firstName: resData.firstName ?? "",
      lastName: resData.lastName ?? "",
      dob: common.dateFormat(resData.dob, "A"),
      gender: common.FindSelect(types.gender, resData.gender),
      nationality: resData?.nationality
        ? {
            label: resData.nationality?.name,
            value: resData.nationality?._id,
          }
        : "",
      firstLanguage: resData?.firstLanguage ?? "",
      maritalStatus: resData?.maritalStatus
        ? common.FindSelect(types.marital, resData?.maritalStatus)
        : null,
      passportNumber: resData?.passportNumber ?? "",
      // address
      addressWithRegion: resData?.addressWithRegion ?? "",
      address2: resData?.address2 ?? "",
      country: resData?.country
        ? {
            label: resData?.country?.name,
            value: resData?.country?._id,
          }
        : "",
      province: resData?.province ?? "",
      city: resData?.city ?? "",

      // province: resData?.province
      //   ? {
      //       label: resData?.province,
      //       value: resData?.province,
      //     }
      //   : "",
      // city: resData?.city
      //   ? {
      //       label: resData?.city,
      //       value: resData?.city,
      //     }
      //   : "",
      postalCode: resData?.postalCode ?? "",
      email: resData?.email ?? "",
      mobile: resData?.mobile ?? "",
      mobileCode: resData?.mobileCode ?? "+91",
      // education
      countryOfEducation: resData?.countryOfEducation
        ? {
            label: resData?.countryOfEducation?.name,
            value: resData?.countryOfEducation?._id,
          }
        : "",
      highLevelOfEducation: resData?.highLevelOfEducation
        ? common.FindSelect(
            types.educationLevelProfile,
            resData?.highLevelOfEducation
          )
        : "",
      grade: resData?.grade ?? "",
      // college
      college: resData?.college ?? [],
      // exam
      exam: resData?.exam ?? [],
      // background
      isVisaBeforeECA: resData?.isVisaBeforeECA ?? "No",
      isVisaRejectedECA: resData?.isVisaRejectedECA ?? "No",
      // work
      workExp: resData?.workExp ?? [],
      // document
      passportDocs: resData?.passportDocs ?? [],
      visaDocs: resData.visaDocs ?? "",
      otherActivity: [],
      extraCurricular: [],
    };

    // default array
    for (var key of Object.keys(formFields)) {
      if (types.studentArrayFields.includes(key)) {
        Object.keys(formFields[key]).forEach((i) => {
          if (i === "docs") {
            formFields[key][i] = [];
          } else {
            formFields[key][i] = "";
          }
        });
      }
    }

    // Array: college
    if (!result.college.length) {
      document.college.push([]);
      result.college = [formFields.college];
    } else {
      document.college.push([]);
      result.college.map((i, k) => {
        i.institutionCountry = i.institutionCountry
          ? {
              label: i.institutionCountry?.name,
              value: i.institutionCountry?._id,
            }
          : null;
        i.startDate = common.dateFormat(i.startDate, "A");
        i.endDate = common.dateFormat(i.endDate, "A");
        i.graduationDate = common.dateFormat(i.graduationDate, "A");
        i.gradingSystem = common.FindSelect(
          types.gradingSystem,
          i.gradingSystem
        );
      });
      // edit
      if (formIndex === "new") {
        document.college.push([]);
        result.college.push(formFields.college);
      }
    }

    // Array: Exam
    if (!result.exam.length) {
      document.exam.push([]);
      result.exam = [formFields.exam];
    } else {
      result.exam.map((i, k) => {
        document.exam.push([]);
        i.examType = common.FindSelect(types.test, i.examType);
        i.dateOfIssue = common.dateFormat(i.dateOfIssue, "A");
      });
      // edit
      if (formIndex === "new") {
        document.exam.push([]);
        result.exam.push(formFields.exam);
      }
    }

    // Array: WorkExp
    if (!result.workExp.length) {
      document.workExp.push([]);
      result.workExp = [formFields.workExp];
    } else {
      result.workExp.map((i, k) => {
        document.workExp.push([]);
        i.joinDate = common.dateFormat(i.joinDate, "A");
        i.endDate = common.dateFormat(i.endDate, "A");
      });
      // edit
      if (formIndex === "new") {
        document.workExp.push([]);
        result.workExp.push(formFields.workExp);
      }
    }

    if (!resData.otherActivity) {
      resData.otherActivity = [formFields.otherActivity];
    }
    if (!resData.extraCurricular) {
      resData.extraCurricular = [formFields.extraCurricular];
    }

    // set error
    let error = {};
    let errorFields = common.reParse(formFields);

    for (var key of Object.keys(errorFields)) {
      if (types.studentArrayFields.includes(key)) {
        Object.keys(errorFields[key]).forEach((i) => {
          errorFields[key][i] = "";
        });
        error[key] = [];
        result[key].map(() => {
          error[key].push(errorFields[key]);
        });
      } else {
        Object.keys(errorFields[key]).forEach((i) => {
          error[i] = "";
        });
      }
    }

    return { fields: result, errors: error, document };
  };

  const getFields = (type) => {
    let newFields = common.reParse(fields);
    let result = {};
    type.map((tItem) => {
      if (types.studentArrayFields.includes(tItem)) {
        result[tItem] = [];
        newFields[tItem].map((i) => {
          for (var key of Object.keys(i)) {
            if (!["docs"].includes(key)) {
              i[key] = i[key]?.value ?? i[key];
            }
          }
          result[tItem].push(i);
        });
      } else {
        for (var key of Object.keys(studentFields[tItem])) {
          result[key] = newFields[key]?.value ?? newFields[key];
        }
      }
    });
    return result;
  };

  const addNew = (type) => {
    let formFields = common.reParse(studentFields);
    // document
    setDocs((prev) => {
      let nData = { ...prev };
      nData[type].push([]);
      return nData;
    });
    // fields
    setFields((prev) => {
      let nData = { ...prev };
      if (["college", "exam", "workExp"].includes(type)) {
        formFields[type].docs = [];
      }
      nData[type].push(formFields[type]);
      return nData;
    });
    // errors
    let e = common.reParse(errors);
    Object.keys(formFields[type]).forEach((i) => (formFields[type][i] = ""));
    e[type].push(formFields[type]);
    setErrors(e);
  };

  const removeNew = (type, i) => {
    let doc = { ...docs };
    doc[type].splice(i, 1);
    setDocs(doc);

    let fld = { ...fields };

    fld[type].splice(i, 1);
    setFields(fld);
  };

  const addDocs = (type, value, multiple = true, k) => {
    if (type !== "passportDocs") {
      scrollToBottom();
    }

    if (multiple) {
      if (type === "passportDocs") {
        setDocs((prev) => {
          let nData = { ...prev };
          nData[type].push(...value.acceptedFiles);
          return nData;
        });
        setFields((prev) => {
          let f = prev;
          f[type] = [
            ...f[type],
            ...value.acceptedFiles?.map((item) => item?.name),
          ];
          return f;
        });
        setErrors((prev) => {
          let f = prev;
          f[type] = "";
          return f;
        });
      } else {
        setDocs((prev) => {
          let nData = { ...prev };
          nData[type][value.id].push(...value.acceptedFiles);
          return nData;
        });
        setFields((prev) => {
          let f = prev;
          f[type][k].docs = [...f[type][k].docs, value.acceptedFiles[0].name];
          return f;
        });
      }
    } else {
      setFields((prev) => ({ ...prev, [type]: value.acceptedFiles }));
      setDocs((prev) => ({ ...prev, [type]: value.acceptedFiles }));
    }
  };

  const onYes = () => {
    //setDocsModal
    if (confirm[0] === "docs") {
      if (confirm.length == 5 && confirm[4]) {
        if ([confirm[1]] == "passportDocs") {
          if (confirm[4].match(/live|dev|file/)) {
            setFields((prev) => {
              let nData = { ...prev };
              nData[confirm[1]].splice(confirm[3], 1);
              return nData;
            });
          } else {
            setDocs((prev) => {
              let nData = { ...prev };
              let data = [];
              if (nData[confirm[1]].length > 0) {
                data = nData[confirm[1]].filter(
                  (obj) => obj.name != confirm[4]
                );
              }
              nData[confirm[1]] = data || [];
              return nData;
            });
            setFields((prev) => {
              let nData = { ...prev };
              let data = [];
              if (nData[confirm[1]].length > 0) {
                data = nData[confirm[1]].filter((obj) => obj != confirm[4]);
              }
              nData[confirm[1]] = data || [];
              return nData;
            });
          }
        } else if (confirm[4].match(/live|dev|file/)) {
          setFields((prev) => {
            let nData = { ...prev };
            nData[confirm[1]][confirm[2]].docs.splice(confirm[3], 1);
            return nData;
          });
        } else {
          setDocs((prev) => {
            let nData = { ...prev };
            let data = [];
            if (nData[confirm[1]][confirm[2]].length > 0) {
              data = nData[confirm[1]][confirm[2]].filter(
                (obj) => obj.name != confirm[4]
              );
            }
            nData[confirm[1]][confirm[2]] = data || [];
            return nData;
          });
          setFields((prev) => {
            let nData = { ...prev };
            let data = [];
            if (nData[confirm[1]][confirm[2]].docs.length > 0) {
              data = nData[confirm[1]][confirm[2]].docs.filter(
                (obj) => obj != confirm[4]
              );
            }
            nData[confirm[1]][confirm[2]].docs = data || [];
            return nData;
          });
        }
      } else {
        console.log("delete docs here", docs);
      }
    } else if (confirm[0] === "fields") {
      setDocs((prev) => {
        let nData = { ...prev };
        nData[confirm[1]][confirm[2]].splice(confirm[3], 1);
        return nData;
      });
    } else if (confirm[0] === "docs-single") {
      setDocs((prev) => ({ ...prev, [confirm[1]]: [] }));
    } else if (confirm[0] === "docs-single-fields") {
      setFields((prev) => ({ ...prev, [confirm[1]]: "" }));
    }
    setConfirm(null);
  };

  // elements
  const InputElement = (
    name,
    label,
    size = 6,
    readOnly = false,
    sub = null,
    key = null
  ) => {
    return (
      <Col md={size} className="mb-3">
        <label className="form-label">{label}</label>
        <div className="signin-form-row">
          <input
            type="text"
            name={name}
            className="form-control"
            value={sub ? fields[sub][key][name] : fields?.[name]}
            onChange={(e) => onInputChange(e, sub, key)}
            readOnly={readOnly}
            placeholder={`Please enter the ${label}`}
          />
          {name == "passportNumber" && (
            <span className="warning-txt">
              In order to get a complete offer, we require a Passport
            </span>
          )}
        </div>
        <p className="error-txt">
          {errorServer?.[name] ??
            (sub ? errors?.[sub]?.[key]?.[name] : errors?.[name])}
        </p>
      </Col>
    );
  };

  const DatePickerElement = (name, label, size = 6, sub = null, key = null) => {
    let set = true;
    return (
      <Col md={size} className="mb-3">
        <label className="form-label">{label}</label>
        <div className="signin-form-row date-picker">
          <DateRangePicker
            initialSettings={{
              ...config.elements.singleDp,
              ...(name == "dob"
                ? {
                    startDate: "1997-05-05",
                    minYear: parseInt(
                      moment().subtract(70, "years").format("Y")
                    ),
                    maxYear: parseInt(
                      moment().subtract(15, "years").format("Y")
                    ),
                  }
                : {}),
            }}
            // onShow={(event, picker) => {
            //   onDatePickerChange(name, picker.startDate, sub, key);
            // }}
            onCallback={(end) => {
              onDatePickerChange(name, end, sub, key);
            }}
          >
            <input
              type="text"
              className="form-control date-picker"
              value={sub ? fields[sub][key][name] : fields?.[name]}
              placeholder={`Please select the ${label}`}
              readOnly={true}
            />
          </DateRangePicker>
          <button
            className="clear-icn"
            onClick={() => onDatePickerChange(name, "", sub, key)}
          >
            <svg
              height="20"
              width="20"
              viewBox="0 0 20 20"
              aria-hidden="true"
              focusable="false"
              className="css-tj5bde-Svg"
            >
              <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
            </svg>
          </button>
          <p className="error-txt">
            {sub ? errors?.[sub]?.[key]?.[name] : errors?.[name]}
          </p>
        </div>
      </Col>
    );
  };

  const SelectElement = (
    type,
    name,
    label,
    size = 6,
    sub = null,
    key = null
  ) => {
    return (
      <Col md={size} className="mb-3">
        <label className="form-label">{label}</label>
        <div className="signin-form-row">
          <Select
            value={sub ? fields[sub][key][name] : fields?.[name]}
            options={types[type]}
            className="custom-select-box"
            isClearable={true}
            onChange={(data) => onSelectChange(name, data, sub, key)}
          />
        </div>
        <p className="error-txt">
          {sub ? errors?.[sub]?.[key]?.[name] : errors?.[name]}
        </p>
      </Col>
    );
  };

  const SelectServerElement = (
    type,
    name,
    label,
    size = 6,
    sub = null,
    key = null
  ) => {
    return (
      <Col md={size}>
        <label className="form-label">{label}</label>
        <div className="signin-form-row">
          <Select
            value={sub ? fields[sub][key][name] : fields?.[name]}
            options={data[type]}
            className="custom-select-box"
            isClearable={true}
            onChange={(data) => onSelectChange(name, data, sub, key)}
          />
        </div>
        <p className="error-txt">
          {sub ? errors?.[sub]?.[key]?.[name] : errors?.[name]}
        </p>
      </Col>
    );
  };

  const MobileInputElement = (name1, name2, label, size = 6) => {
    return (
      <Col md={size}>
        <label className="form-label">{label}</label>
        <div className="row">
          <div className="col-md-12 col-sm-10 signin-form-row">
            {/* <input
              type="text"
              name={name2}
              className="form-control"
              value={fields?.[name2]}
              onChange={(e) => onNumberInputChange(e, null, null)}
              maxLength={17}
            /> */}
            <PhoneInput
              inputProps={{
                name: "mobile",
                // required: true,
                // autoFocus: true,
              }}
              value={fields?.[name2]}
              country={"lk"}
              onChange={(phone) =>
                onNumberInputChange({ target: { name: name2, value: phone } })
              }
            />
          </div>
        </div>
        <p className="error-txt">{errors[name2]}</p>
      </Col>
    );
  };

  // render
  return (
    <Fragment>
      {status !== "success" ? (
        <StatusBar status={status} />
      ) : (
        <Fragment>
          {["personal", "address", "personal_address"].includes(formModule) && (
            <Fragment>
              <div className={!formModal ? "" : ""}>
                {!formModal && (
                  <div className="register-step-header-general res-generalinfo-wrap">
                    <p className="register-step-title mb-0">
                      General Information
                    </p>
                  </div>
                )}
                {["personal", "personal_address"].includes(formModule) && (
                  <Fragment>
                    <div className="register-step-header d-flex justify-content-between align-items-center">
                      <p className="register-step-title mb-0">Personal</p>
                      {formModal && (
                        <div className="cp" onClick={props?.onCancel}>
                          <img
                            src={common.loadImg("close-icn.svg")}
                            alt=""
                            className="et-cp"
                          />
                        </div>
                      )}
                    </div>
                    <div className="register-step-content">
                      <Row>
                        {InputElement("firstName", "First Name")}
                        {InputElement("lastName", "Last Name")}
                        {DatePickerElement("dob", "Date Of Birth")}
                        {SelectElement("gender", "gender", "Gender")}
                        {SelectElement(
                          "marital",
                          "maritalStatus",
                          "Marital Status"
                        )}
                        {InputElement("firstLanguage", "First Language")}
                        {SelectServerElement(
                          "nationality",
                          "nationality",
                          "Nationality"
                        )}
                        {/* {InputElement("passportNumber", "Passport Number")} */}

                        {/* <FileUpload
                          label="Passport Docs"
                         
                          onSuccess={(files) => {
                            setDocsModal((prev) => {
                              let nData = { ...prev };
                              if (
                                docsModal?.edit === null ||
                                docsModal?.edit?.[1] === null
                              ) {
                                nData.docs.push(...files.acceptedFiles);
                              } else {
                                nData.docs = [...files.acceptedFiles];
                              }
                              console.log('check',nData)
                              return nData;
                            })
                            
                          }}
                        />

                        {docsModal?.docs.map((item, key) => (
                          <div
                            className="d-flex align-items-center justify-content-between pt-3"
                            key={key}
                          >
                            <p className="agt-proof-file mb-0">{item.name}</p>
                            <img
                              src={common.loadImg("iconly-dark-delete.svg")}
                              className="et-cp"
                              onClick={() => setConfirm(["modal", key])}
                            />
                          </div>
                        ))} */}
                        {/* Moved to Background */}
                        {/* <FileUpload
                          label="Passport Document"
                          onSuccess={(files) =>
                            addDocs("passportDocs", files, true, 0)
                          }
                        />
                        <span className="warning-txt">
                          In order to get a complete offer, we require a
                          Passport
                        </span> */}
                        {/* already added */}
                        {/* {fields?.passportDocs.map((item, key) => {
                          return item.match(/live|dev|file/) ? (
                            <div className="d-flex align-items-center justify-content-between pt-3">
                              <p
                                className="agt-proof-file mb-0 et-link"
                                onClick={() =>
                                  item.match(/live|dev|file/)
                                    ? common.openCloudUrl(item)
                                    : null
                                }
                              >
                                {item.match(/live|dev|file/)
                                  ? common.fileName(item)
                                  : item}
                              </p>
                              <img
                                src={common.loadImg("iconly-dark-delete.svg")}
                                className="et-cp"
                                onClick={() =>
                                  setConfirm([
                                    "docs",
                                    "passportDocs",
                                    0,
                                    key,
                                    item,
                                  ])
                                }
                              />
                            </div>
                          ) : null;
                        })} */}

                        {/* newly added */}
                        {/* {docs?.passportDocs?.map((item, key) => (
                          <div
                            className="d-flex align-items-center justify-content-between pt-3"
                            key={key}
                          >
                            <p className="agt-proof-file mb-0">{item.name}</p>
                            <img
                              src={common.loadImg("iconly-dark-delete.svg")}
                              className="et-cp"
                              onClick={() =>
                                setConfirm([
                                  "docs",
                                  "passportDocs",
                                  0,
                                  key,
                                  item.name,
                                ])
                              }
                            />
                          </div>
                        ))} */}
                        {}
                        {/* <p className="error-txt">{errors?.passportDocs}</p> */}
                      </Row>
                      <div className="ref-div" ref={workDocRef}></div>
                    </div>
                  </Fragment>
                )}

                {["address", "personal_address"].includes(formModule) && (
                  <Fragment>
                    <div className="register-step-header d-flex justify-content-between align-items-center">
                      <p className="register-step-title mb-0">Address</p>
                      {formModal && (
                        <div className="cp" onClick={props?.onCancel}>
                          <img
                            src={common.loadImg("close-icn.svg")}
                            alt=""
                            className="et-cp"
                          />
                        </div>
                      )}
                    </div>
                    <div className="register-step-content">
                      <Row>
                        {InputElement(
                          "addressWithRegion",
                          "Address with Region",
                          12
                        )}
                        {InputElement("address2", "Address Line 2", 12)}

                        {InputElement("province", "province")}
                        {InputElement("city", "City / Town")}
                        {SelectServerElement(
                          "countrySelect",
                          "country",
                          "Country"
                        )}

                        {/* <Col md={6}>
                        <label className="form-label">Province</label>
                      <div className="signin-form-row">
                        <FieldSelect
                          api="common_state"
                          query={`/${fields?.country?.value}?&offset=1&limit=1000`}
                          get="name"
                          set="name"
                          value={fields.province}
                          placeholder="Please select the province"
                          render={selectRender.province}
                          clearable={true}
                          findValue={false}
                          multi={false}
                          auth="token"
                          onSelect={(data) => {
                            setFields((prev) => ({
                              ...prev,
                              province: data,
                              city:null
                            }));
                            setSelectRender((prev) => ({
                              ...prev,
                              city: data === null ? "empty" : new Date(),
                            }));
                          }}
                         
                        />
                        <p className="error-txt">
                          {errors?.province}
                        </p>
                        </div>

                        </Col>
                        <Col md={6}>

                        <label className="form-label">City / Town</label>
                      <div className="signin-form-row">
                        <FieldSelect
                          api="common_city_v2"
                          query={`/${fields?.country?.value}/${fields?.province?.value}?&offset=1&limit=1000`}
                          get="name"
                          set="name"
                          value={fields.city}
                          placeholder="Please select the city"
                          render={selectRender.city}
                          clearable={true}
                          findValue={false}
                          multi={false}
                          auth="token"
                          onSelect={(data) => {
                            setFields((prev) => ({
                              ...prev,
                              city: data,
                            }));
                          }}
                         
                        />
                        <p className="error-txt">
                          {errors?.city}
                        </p>
                        </div>
                        </Col> */}

                        {InputElement("postalCode", "Postal Code")}
                        {InputElement(
                          "email",
                          "Email",
                          6,
                          authData.role === "student" ? true : false
                        )}
                        {MobileInputElement("mobileCode", "mobile", "Mobile")}
                      </Row>
                    </div>
                  </Fragment>
                )}
                <Col lg={12} className="register-step-footernew">
                  <Link to="#">
                    <button
                      className="btn-default"
                      onClick={() => onSubmit(1)}
                      disabled={loading}
                    >
                      {loading ? "Procesing..." : "Save & Update"}
                    </button>
                  </Link>
                </Col>
              </div>
            </Fragment>
          )}
          {["education", "college", "education_college"].includes(
            formModule
          ) && (
            <Fragment>
              <div className={!formModal ? "" : ""}>
                {["education", "education_college"].includes(formModule) && (
                  <Fragment>
                    {!formModal && (
                      <div className="register-step-header-general">
                        <p className="register-step-title mb-0">
                          Educational Information
                        </p>
                      </div>
                    )}
                    <div className="register-step-header d-flex justify-content-between align-items-center">
                      <p className="register-step-title mb-0">General</p>
                      {formModal && (
                        <div className="cp" onClick={props?.onCancel}>
                          <img
                            src={common.loadImg("close-icn.svg")}
                            alt=""
                            className="et-cp"
                          />
                        </div>
                      )}
                    </div>
                    <div className="register-step-content">
                      <Row>
                        {SelectServerElement(
                          "countrySelect",
                          "countryOfEducation",
                          "Country of Education"
                        )}
                        {SelectElement(
                          "educationLevelProfile",
                          "highLevelOfEducation",
                          "Highest level of Education"
                        )}
                        {InputElement("grade", "Grade")}
                      </Row>
                    </div>
                  </Fragment>
                )}
                {["college", "education_college"].includes(formModule) && (
                  <Fragment>
                    <div className="register-step-header d-flex justify-content-between align-items-center">
                      <p className="register-step-title mb-0">College</p>
                      {formModal && (
                        <div className="cp" onClick={props?.onCancel}>
                          <img
                            src={common.loadImg("close-icn.svg")}
                            alt=""
                            className="et-cp"
                          />
                        </div>
                      )}
                    </div>
                    {fields.college.map((i, k) => {
                      let allow = false;
                      if (formIndex === "all") {
                        allow = true;
                      } else if (
                        formIndex === "new" &&
                        fields.college.length === Number(k) + 1
                      ) {
                        allow = true;
                      } else if (formIndex === k) {
                        allow = true;
                      }
                      if (allow) {
                        return (
                          <Fragment key={k}>
                            {formAddNew && (
                              <div className="register-step-header1 brderclr d-flex justify-content-between">
                                <p className="titlle">0{Number(k) + 1}</p>
                                {k !== 0 ? (
                                  <p
                                    className="newtitle"
                                    onClick={() => removeNew("college", k)}
                                  >
                                    <img
                                      src={common.loadImg("delete-icn.svg")}
                                      className="ms-3 et-cp"
                                    />
                                  </p>
                                ) : null}
                              </div>
                            )}
                            <div className="register-step-content">
                              <Row>
                                {SelectServerElement(
                                  "countrySelect",
                                  "institutionCountry",
                                  "Country of Institution",
                                  6,
                                  "college",
                                  k
                                )}

                                {InputElement(
                                  "institutionName",
                                  "Name of Institution",
                                  6,
                                  false,
                                  "college",
                                  k
                                )}
                                {InputElement(
                                  "qualification",
                                  "Qualification",
                                  6,
                                  false,
                                  "college",
                                  k
                                )}
                                {InputElement(
                                  "degree",
                                  "Degree Name",
                                  6,
                                  false,
                                  "college",
                                  k
                                )}
                                {DatePickerElement(
                                  "startDate",
                                  "Start Date",
                                  6,
                                  "college",
                                  k
                                )}
                                {DatePickerElement(
                                  "endDate",
                                  "End Date",
                                  6,
                                  "college",
                                  k
                                )}
                                {DatePickerElement(
                                  "graduationDate",
                                  "Graduation Date",
                                  6,
                                  "college",
                                  k
                                )}
                                {SelectElement(
                                  "gradingSystem",
                                  "gradingSystem",
                                  "Grading System",
                                  6,
                                  "college",
                                  k
                                )}
                                {InputElement(
                                  "score",
                                  "Score",
                                  6,
                                  false,
                                  "college",
                                  k
                                )}
                                <div className="dd-file-wrap">
                                  <FileUpload
                                    id={k}
                                    onSuccess={(files) =>
                                      addDocs("college", files, true, k)
                                    }
                                  />
                                </div>
                                {/* already added */}
                                {fields?.college[k]?.docs?.length > 0 &&
                                  fields?.college[k]?.docs?.map((item, key) => (
                                    <div
                                      className="d-flex align-items-center justify-content-between pt-3"
                                      key={key}
                                    >
                                      <p
                                        className="agt-proof-file mb-0 et-link"
                                        onClick={() =>
                                          item.match(/live|dev|file/)
                                            ? common.openCloudUrl(item)
                                            : null
                                        }
                                      >
                                        {item.match(/live|dev|file/)
                                          ? common.fileName(item)
                                          : item}
                                      </p>
                                      <img
                                        src={common.loadImg(
                                          "iconly-dark-delete.svg"
                                        )}
                                        className="et-cp"
                                        onClick={() => {
                                          setConfirm([
                                            "docs",
                                            "college",
                                            k,
                                            key,
                                            item,
                                          ]);
                                        }}
                                      />
                                    </div>
                                  ))}
                                {/* newly added */}
                                {/* {docs.college[k].map((item, key) => (
                                    <div
                                      className="d-flex align-items-center justify-content-between pt-3"
                                      key={key}
                                    >
                                      <p className="agt-proof-file mb-0">
                                        {item.name}
                                      </p>
                                      <img
                                        src={common.loadImg(
                                          "iconly-dark-delete.svg"
                                        )}
                                        className="et-cp"
                                        onClick={() =>
                                          setConfirm([
                                            "fields",
                                            "college",
                                            k,
                                            key,
                                          ])
                                        }
                                      />
                                    </div>
                                  ))} */}
                                <p className="error-txt">
                                  {errors.college[k].docs}
                                </p>
                              </Row>
                              <div className="ref-div" ref={workDocRef}></div>
                            </div>
                          </Fragment>
                        );
                      }
                    })}
                  </Fragment>
                )}

                {formAddNew && (
                  <div className="register-step-header1">
                    <p className="newtitle" onClick={() => addNew("college")}>
                      0{Number(fields.college.length) + 1}. Add another
                      Institute{" "}
                      <img
                        src={common.loadImg("plus-icn-blue.svg")}
                        className="ms-3 et-cp"
                      />
                    </p>
                  </div>
                )}
                <div className="register-step-footernew">
                  <Link to="">
                    <button
                      className="btn-default"
                      onClick={() => onSubmit(2)}
                      disabled={loading}
                    >
                      {loading ? "Procesing..." : "Save & Update"}
                    </button>
                  </Link>
                </div>
              </div>
            </Fragment>
          )}
          {["exam"].includes(formModule) && (
            <Fragment>
              <div className={!formModal ? "" : ""}>
                {["exam", "exam_background"].includes(formModule) && (
                  <Fragment>
                    <div
                      className={`register-step-header${
                        !formModal ? "-general" : ""
                      } d-flex justify-content-between align-items-center`}
                    >
                      <p className="register-step-title mb-0">Test Score</p>
                      {formModal && (
                        <div className="cp" onClick={props?.onCancel}>
                          <img
                            src={common.loadImg("close-icn.svg")}
                            alt=""
                            className="et-cp"
                          />
                        </div>
                      )}
                    </div>
                    {fields.exam.map((i, k) => {
                      let allow = false;
                      if (formIndex === "all") {
                        allow = true;
                      } else if (
                        formIndex === "new" &&
                        fields.exam.length === Number(k) + 1
                      ) {
                        allow = true;
                      } else if (formIndex === k) {
                        allow = true;
                      }
                      if (allow) {
                        return (
                          <Fragment key={k}>
                            {formAddNew && (
                              <div className="register-step-header1 brderclr d-flex justify-content-between">
                                <p className="titlle">0{Number(k) + 1}.</p>
                                {k !== 0 ? (
                                  <p
                                    className="newtitle"
                                    onClick={() => removeNew("exam", k)}
                                  >
                                    <img
                                      src={common.loadImg("delete-icn.svg")}
                                      className="ms-3 et-cp"
                                    />
                                  </p>
                                ) : null}
                              </div>
                            )}
                            <div className="register-step-content">
                              <Row>
                                {SelectElement(
                                  "test",
                                  "examType",
                                  "Test Name",
                                  6,
                                  "exam",
                                  k
                                )}
                                <Col md={6}>
                                  <label className="form-label">Score</label>
                                  <div className="signin-form-row">
                                    <div className="d-flex justify-content-between align-items-center res-testcourse-wrap">
                                      <div className="chk-btn-wrap me-3">
                                        <input
                                          type="text"
                                          name="listening"
                                          className="form-control"
                                          placeholder="Listening"
                                          value={fields.exam[k].listening}
                                          onChange={(e) =>
                                            onInputChangeScore(e, k)
                                          }
                                        />
                                      </div>
                                      <div className="chk-btn-wrap me-3">
                                        <input
                                          type="text"
                                          name="reading"
                                          className="form-control"
                                          placeholder="Reading"
                                          value={fields.exam[k].reading}
                                          onChange={(e) =>
                                            onInputChangeScore(e, k)
                                          }
                                        />
                                      </div>
                                      <div className="chk-btn-wrap me-3">
                                        <input
                                          type="text"
                                          name="writing"
                                          className="form-control"
                                          placeholder="Writing"
                                          value={fields.exam[k].writing}
                                          onChange={(e) =>
                                            onInputChangeScore(e, k)
                                          }
                                        />
                                      </div>
                                      <div className="chk-btn-wrap">
                                        <input
                                          type="text"
                                          name="speaking"
                                          className="form-control"
                                          placeholder="Speaking"
                                          value={fields.exam[k].speaking}
                                          onChange={(e) =>
                                            onInputChangeScore(e, k)
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                                {DatePickerElement(
                                  "dateOfIssue",
                                  "Date Of Issue",
                                  6,
                                  "exam",
                                  k
                                )}

                                {InputElement(
                                  "testScore",
                                  "Overall Score",
                                  6,
                                  true,
                                  "exam",
                                  k
                                )}

                                <div className="dd-file-wrap">
                                  <FileUpload
                                    id={k}
                                    onSuccess={(files) =>
                                      addDocs("exam", files, true, k)
                                    }
                                  />
                                </div>
                                {/* already added */}
                                {fields.exam[k].docs?.length > 0 &&
                                  fields.exam[k].docs.map((item, key) => (
                                    <div
                                      className="d-flex align-items-center justify-content-between pt-3"
                                      key={key}
                                    >
                                      <p
                                        className="agt-proof-file mb-0 et-link"
                                        onClick={() =>
                                          item.match(/live|dev|file/)
                                            ? common.fileName(item)
                                            : null
                                        }
                                      >
                                        {item.match(/live|dev|file/)
                                          ? common.fileName(item)
                                          : item}
                                      </p>
                                      <img
                                        src={common.loadImg(
                                          "iconly-dark-delete.svg"
                                        )}
                                        className="et-cp"
                                        onClick={() =>
                                          setConfirm([
                                            "docs",
                                            "exam",
                                            k,
                                            key,
                                            item,
                                          ])
                                        }
                                      />
                                    </div>
                                  ))}
                                {/* newly added */}
                                {/* {docs.exam[k].map((item, key) => (
                                  <div
                                    className="d-flex align-items-center justify-content-between pt-3"
                                    key={key}
                                  >
                                    <p className="agt-proof-file mb-0">
                                      {item.name}
                                    </p>
                                    <img
                                      src={common.loadImg(
                                        "iconly-dark-delete.svg"
                                      )}
                                      className="et-cp"
                                      onClick={() =>
                                        setConfirm(["fields", "exam", k, key])
                                      }
                                    />
                                  </div>
                                ))} */}
                              </Row>
                              <div className="ref-div" ref={workDocRef}></div>
                            </div>
                          </Fragment>
                        );
                      }
                    })}

                    {formAddNew && (
                      <div className="register-step-header1">
                        <p className="newtitle" onClick={() => addNew("exam")}>
                          0{Number(fields.exam.length) + 1}. Add another Test{" "}
                          <img
                            src={common.loadImg("plus-icn-blue.svg")}
                            className="ms-3 et-cp"
                          />
                        </p>
                      </div>
                    )}
                  </Fragment>
                )}

                {["background", "exam_background"].includes(formModule) && (
                  <Fragment>
                    <div
                      className={`register-step-header${
                        !formModal ? "-general" : ""
                      } d-flex justify-content-between align-items-center`}
                    >
                      <p className="register-step-title mb-0">
                        Background Information
                      </p>
                      {formModal && (
                        <div className="cp" onClick={props?.onCancel}>
                          <img
                            src={common.loadImg("close-icn.svg")}
                            alt=""
                            className="et-cp"
                          />
                        </div>
                      )}
                    </div>
                    <div className="register-step-content">
                      <Row className="d-flex justify-content-between align-items-center maincont">
                        <Col lg={6} className="bginfocontent">
                          <p>
                            Did you apply for a visa before as a part of your
                            entry clearance application?
                          </p>
                        </Col>
                        <Col
                          lg={6}
                          className="d-flex align-items-center justify-content-end"
                        >
                          <div className="bgbutton">
                            <input
                              type="radio"
                              id="a25"
                              name="check-substitution-2"
                              className="form-control"
                              checked={fields.isVisaBeforeECA === "Yes"}
                              onChange={() =>
                                setFields((prev) => ({
                                  ...prev,
                                  isVisaBeforeECA: "Yes",
                                }))
                              }
                            />
                            <label className="btn btn-default" htmlFor="a25">
                              Yes
                            </label>
                          </div>
                          <div className="bgbutton margino">
                            <input
                              type="radio"
                              id="a50"
                              name="check-substitution-2"
                              className="form-control"
                              checked={fields.isVisaBeforeECA === "No"}
                              onChange={() =>
                                setFields((prev) => ({
                                  ...prev,
                                  isVisaBeforeECA: "No",
                                }))
                              }
                            />
                            <label className="btn btn-default" htmlFor="a50">
                              No
                            </label>
                          </div>
                        </Col>
                      </Row>
                      <Row className="d-flex justify-content-between align-items-center mt-3">
                        <Col lg={6} className="bginfocontent">
                          <p>
                            Did your visa have been rejected before as a part of
                            your entry clearance application?
                          </p>
                        </Col>
                        <Col
                          lg={6}
                          className="d-flex align-items-center justify-content-end"
                        >
                          <div className="bgbutton">
                            <input
                              type="radio"
                              id="a26"
                              name="check-substitution-3"
                              className="form-control"
                              checked={fields.isVisaRejectedECA === "Yes"}
                              onChange={() =>
                                setFields((prev) => ({
                                  ...prev,
                                  isVisaRejectedECA: "Yes",
                                }))
                              }
                            />
                            <label className="btn btn-default" htmlFor="a26">
                              Yes
                            </label>
                          </div>
                          <div className="bgbutton margino">
                            <input
                              type="radio"
                              id="a51"
                              name="check-substitution-3"
                              className="form-control"
                              checked={fields.isVisaRejectedECA === "No"}
                              onChange={() =>
                                setFields((prev) => ({
                                  ...prev,
                                  isVisaRejectedECA: "No",
                                }))
                              }
                            />
                            <label className="btn btn-default" htmlFor="a51">
                              No
                            </label>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Fragment>
                )}

                <div className="register-step-footernew">
                  {formType === "agentNext" && (
                    <Link to={`/student/edit/${id}/${nav[type]}/2`}>
                      <button className="btn-default mx-3">Skip</button>
                    </Link>
                  )}
                  <Link to="">
                    <button
                      className="btn-default"
                      onClick={() => onSubmit(3)}
                      disabled={loading}
                    >
                      {loading ? "Procesing..." : "Save & Update"}
                    </button>
                  </Link>
                </div>
              </div>
            </Fragment>
          )}

          {["background", "exam_background"].includes(formModule) && (
            <Fragment>
              <div
                className={`register-step-header${
                  !formModal ? "-general" : ""
                } d-flex justify-content-between align-items-center`}
              >
                <p className="register-step-title mb-0">
                  Background Information
                </p>
                {formModal && (
                  <div className="cp" onClick={props?.onCancel}>
                    <img
                      src={common.loadImg("close-icn.svg")}
                      alt=""
                      className="et-cp"
                    />
                  </div>
                )}
              </div>
              <div className="register-step-content">
                {InputElement("passportNumber", "Passport Number")}
                <FileUpload
                  label="Passport Document"
                  onSuccess={(files) => addDocs("passportDocs", files, true, 0)}
                />
                <span className="warning-txt">
                  In order to get a complete offer, we require a Passport
                </span>
                {/* already added */}
                {fields?.passportDocs.map((item, key) => {
                  return item.match(/live|dev|file/) ? (
                    <div className="d-flex align-items-center justify-content-between pt-3">
                      <p
                        className="agt-proof-file mb-0 et-link"
                        onClick={() =>
                          item.match(/live|dev|file/)
                            ? common.openCloudUrl(item)
                            : null
                        }
                      >
                        {item.match(/live|dev|file/)
                          ? common.fileName(item)
                          : item}
                      </p>
                      <img
                        src={common.loadImg("iconly-dark-delete.svg")}
                        className="et-cp"
                        onClick={() =>
                          setConfirm(["docs", "passportDocs", 0, key, item])
                        }
                      />
                    </div>
                  ) : null;
                })}
                {/* newly added */}
                {docs?.passportDocs?.map((item, key) => (
                  <div
                    className="d-flex align-items-center justify-content-between pt-3"
                    key={key}
                  >
                    <p className="agt-proof-file mb-0">{item.name}</p>
                    <img
                      src={common.loadImg("iconly-dark-delete.svg")}
                      className="et-cp"
                      onClick={() =>
                        setConfirm(["docs", "passportDocs", 0, key, item.name])
                      }
                    />
                  </div>
                ))}
                <Row className="d-flex justify-content-between align-items-center maincont">
                  <Col lg={6} className="bginfocontent">
                    <p>
                      Did you apply for a visa before as a part of your entry
                      clearance application?
                    </p>
                  </Col>
                  <Col lg={6}>
                    <div className="d-flex align-items-center justify-content-end">
                      <div className="bgbutton">
                        <input
                          type="radio"
                          id="a25"
                          name="check-substitution-2"
                          className="form-control"
                          checked={fields.isVisaBeforeECA === "Yes"}
                          onChange={() =>
                            setFields((prev) => ({
                              ...prev,
                              isVisaBeforeECA: "Yes",
                            }))
                          }
                        />
                        <label className="btn btn-default" htmlFor="a25">
                          Yes
                        </label>
                      </div>
                      <div className="bgbutton margino">
                        <input
                          type="radio"
                          id="a50"
                          name="check-substitution-2"
                          className="form-control"
                          checked={fields.isVisaBeforeECA === "No"}
                          onChange={() =>
                            setFields((prev) => ({
                              ...prev,
                              isVisaBeforeECA: "No",
                            }))
                          }
                        />
                        <label className="btn btn-default" htmlFor="a50">
                          No
                        </label>
                      </div>
                    </div>
                    <p className="error-txt mt-2 px-3">
                      {errors.isVisaBeforeECA}
                    </p>
                  </Col>
                </Row>
                <Row className="d-flex justify-content-between align-items-center mt-3">
                  <Col lg={6} className="bginfocontent">
                    <p>
                      Did your visa have been rejected before as a part of your
                      entry clearance application?
                    </p>
                  </Col>
                  <Col lg={6} className="align-items-center ">
                    <div className="d-flex align-items-center justify-content-end">
                      <div className="bgbutton">
                        <input
                          type="radio"
                          id="a26"
                          name="check-substitution-3"
                          className="form-control"
                          checked={fields.isVisaRejectedECA === "Yes"}
                          onChange={() =>
                            setFields((prev) => ({
                              ...prev,
                              isVisaRejectedECA: "Yes",
                            }))
                          }
                        />
                        <label className="btn btn-default" htmlFor="a26">
                          Yes
                        </label>
                      </div>
                      <div className="bgbutton margino">
                        <input
                          type="radio"
                          id="a51"
                          name="check-substitution-3"
                          className="form-control"
                          checked={fields.isVisaRejectedECA === "No"}
                          onChange={() =>
                            setFields((prev) => ({
                              ...prev,
                              isVisaRejectedECA: "No",
                            }))
                          }
                        />
                        <label className="btn btn-default" htmlFor="a51">
                          No
                        </label>
                      </div>
                    </div>

                    <p className="error-txt mt-2 px-3">
                      {errors.isVisaRejectedECA}
                    </p>
                  </Col>
                </Row>
              </div>
              <div className="register-step-footernew">
                <Link to="">
                  <button
                    className="btn-default"
                    onClick={() => onSubmit(4)}
                    disabled={loading}
                  >
                    {loading ? "Procesing..." : "Save & Update"}
                  </button>
                </Link>
              </div>
            </Fragment>
          )}

          {["workExp"].includes(formModule) && (
            <Fragment>
              <div className={!formModal ? "" : ""}>
                <div
                  className={`register-step-header${
                    !formModal ? "-general" : ""
                  } d-flex justify-content-between align-items-between`}
                >
                  <p className="register-step-title mb-0">Work History</p>

                  {formModal && (
                    <div className="cp" onClick={props?.onCancel}>
                      <img
                        src={common.loadImg("close-icn.svg")}
                        alt=""
                        className="et-cp"
                      />
                    </div>
                  )}
                </div>
                {fields.workExp.map((i, k) => {
                  let allow = false;
                  if (formIndex === "all") {
                    allow = true;
                  } else if (
                    formIndex === "new" &&
                    fields.workExp.length === Number(k) + 1
                  ) {
                    allow = true;
                  } else if (formIndex === k) {
                    allow = true;
                  }
                  if (allow) {
                    return (
                      <Fragment key={k}>
                        {formAddNew && (
                          <div className="register-step-header1 brderclr d-flex justify-content-between">
                            <p className="titlle">0{Number(k) + 1}.</p>
                            {k !== 0 ? (
                              <p
                                className="newtitle"
                                onClick={() => removeNew("workExp", k)}
                              >
                                <img
                                  src={common.loadImg("delete-icn.svg")}
                                  className="ms-3 et-cp"
                                />
                              </p>
                            ) : null}
                          </div>
                        )}
                        <div className="register-step-content">
                          <Row>
                            {InputElement(
                              "companyName",
                              "Company Name",
                              6,
                              false,
                              "workExp",
                              k
                            )}
                            {InputElement(
                              "designation",
                              "Designation",
                              6,
                              false,
                              "workExp",
                              k
                            )}
                            {InputElement(
                              "lineManagerName",
                              "Line Manager Name",
                              6,
                              false,
                              "workExp",
                              k
                            )}
                            {InputElement(
                              "lineManagerEmail",
                              "Line Manager Work Email",
                              6,
                              false,
                              "workExp",
                              k
                            )}
                            {InputElement(
                              "lineManagerDesignation",
                              "Line Manager Designation",
                              6,
                              false,
                              "workExp",
                              k
                            )}
                            {InputElement(
                              "companyEmail",
                              "Company Email",
                              6,
                              false,
                              "workExp",
                              k
                            )}
                            {InputElement(
                              "companyAddress",
                              "Company Address",
                              6,
                              false,
                              "workExp",
                              k
                            )}
                            {DatePickerElement(
                              "joinDate",
                              "Joining Date",
                              6,
                              "workExp",
                              k
                            )}
                            {DatePickerElement(
                              "endDate",
                              "End Date",
                              6,
                              "workExp",
                              k
                            )}
                            <div className="dd-file-wrap">
                              <FileUpload
                                id={k}
                                onSuccess={(files) =>
                                  addDocs("workExp", files, true, k)
                                }
                              />
                            </div>
                            
                            {/* already added */}
                            {fields.workExp[k].docs.map((item, key) => (
                              <div
                                className="d-flex align-items-center justify-content-between pt-3"
                                key={key}
                              >
                                <p
                                  className="agt-proof-file mb-0 et-link"
                                  onClick={() =>
                                    item.match(/live|dev|file/)
                                      ? common.openCloudUrl(item)
                                      : null
                                  }
                                >
                                  {item.match(/live|dev|file/)
                                    ? common.fileName(item)
                                    : item}
                                </p>
                                <img
                                  src={common.loadImg("iconly-dark-delete.svg")}
                                  className="et-cp"
                                  onClick={() =>
                                    setConfirm([
                                      "docs",
                                      "workExp",
                                      k,
                                      key,
                                      item,
                                    ])
                                  }
                                />
                              </div>
                            ))}
                            {/* newly added */}
                            {/* {docs.workExp[k].map((item, key) => (
                              <div
                                className="d-flex align-items-center justify-content-between pt-3"
                                key={key}
                              >
                                <p className="agt-proof-file mb-0">
                                  {item.name}
                                </p>
                                <img
                                  src={common.loadImg("iconly-dark-delete.svg")}
                                  className="et-cp"
                                  onClick={() =>
                                    setConfirm(["fields", "workExp", k, key])
                                  }
                                />
                              </div>
                            ))} */}
                          </Row>
                          <div className="ref-div" ref={workDocRef}></div>
                        </div>
                      </Fragment>
                    );
                  }
                })}
                {formAddNew && (
                  <div className="register-step-header1">
                    <p className="newtitle" onClick={() => addNew("workExp")}>
                      0{Number(fields.workExp.length) + 1}. Add another Company{" "}
                      <img
                        src={common.loadImg("plus-icn-blue.svg")}
                        className="ms-3 et-cp"
                      />
                    </p>
                  </div>
                )}
                <div className="register-step-footernew">
                  {formType === "agentNext" && (
                    <Link to={`/student`}>
                      <button className="btn-default mx-3">Skip</button>
                    </Link>
                  )}

                  <Link to="">
                    <button
                      className="btn-default"
                      onClick={() => onSubmit(5)}
                      disabled={loading}
                    >
                      {loading ? "Procesing..." : "Save & Update"}
                    </button>
                  </Link>
                </div>
              </div>
            </Fragment>
          )}
          <div className="shadow-div m-0"></div>
          <div className="greenshow-div"></div>
        </Fragment>
      )}
      {confirm && <ModalConfirm onYes={onYes} onNo={() => setConfirm(null)} />}
    </Fragment>
  );
}
export default StudentForm;
