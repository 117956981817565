import { common } from "helpers";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// includes
import AgTable from "elements/AgTable";
import { api } from "helpers";

function List() {
  // init
  const navigate = useNavigate();
  // state
  const [permission] = useState(common.getUserPermission());
  // effect
  useEffect(() => {
    if (!permission.includes("str")) {
      navigate("/profile");
    }
  }, []);
  // functions
  const callAction = (data) => {
    if (data.type === "Add") {
      navigate("/student/add");
    } else if (data.type == "View") {
      navigate("/student/view/" + data.item._id);
    } else if (data.type == "Export" || data.type == "export") {
      exportRecord(data);
    } else if (data.type == "Edit"){
      navigate(`/student/edit/${data.item._id}/personal_address/2`);
    }
  };

  //api
  const exportRecord = (val) => {
    let data = {
      url: "student_export",
      method: "GET",
      query: val.query,
      responseType: "file",
      fileName: "student",
    };
    api.call(data, (res) => {});
  };

  return (
    permission.includes("str") && (
      <AgTable
        module="adminStudent"
        filterData={{
          agentMulti: { key: "agent", value: [] },
          subAgentMulti: { key: "subAgentId", value: [] },
          status: { key: "isActive", value: "" },
          // register: { key: "byRegister", value: "" },
          country: { key: "country", value: "" },
          profileSlider: { key: "profileProgress", value: [0, 100] },
          registerFrom: {
            key: "registeredFrom",
            value: "",
            title: "Registered From",
          },
          dateA: { key: "startDate", value: "", title: "Created Date From" },
          dateB: { key: "endDate", value: "", title: "Created Date To" },
        }}
        actionPermission={common.accessTable(permission, [
          "stc",
          "stu",
          "str",
          "std",
        ])}
        onAction={(data) => callAction(data)}
      />
    )
  );
}

export default List;
