import AmlHashForm from "aml-hash-form";
import { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
// includes
import { api, common } from "helpers";

function SignIn() {
  // init
  const navigate = useNavigate();
  // state
  const [isAuth] = useState(common.checkAuth());
  const [loading, setLoading] = useState(false);
  const [view, setView] = useState(false);
  const [error, setError] = useState({ email: "", password: "" });
  // form
  const formFields = {
    email: {
      validate: [
        "req#Email is required",
        "email#Please enter a valid email address",
      ],
    },
    password: {
      validate: [
        "req#Password is required!",
        "min:8#Password should be minimum 8 Digits",
        "max:15#Password should be maximum 15 Digits",
      ],
    },
  };

  const { fields, errors, handleChange, handleSubmit } =
    AmlHashForm(formFields);

  // effect
  useEffect(() => {
    if (isAuth) {
      navigate("/home");
    }
  }, [isAuth]);

  // handler
  const onSubmit = (e) => {
    e.preventDefault();
    const isValid = handleSubmit();
    if (isValid) {
      Login();
    }
  };

  // Api
  const Login = () => {
    setLoading(true);
    let payload = fields;
    payload["userType"] = "ADMINISTRATOR";
    // api call
    let data = {
      url: "ids_login",
      method: "POST",
      auth: "none",
      body: payload,
    };
    api.call(data, (res) => {
      if (res.status === 500) {
        if (res.data?.code === "incorrect_password") {
          setError((prev) => ({ ...prev, password: res.data.error }));
        } else {
          setError((prev) => ({ ...prev, email: res.data.error }));
        }
      } else {
        let data = res.data;
        common.localSet("adminAuthData", common.crypt(data, true));
        navigate("/home");
      }
      setLoading(false);
    });
  };

  // support
  const resetError = (name) => {
    setError((prev) => ({ ...prev, [name]: "" }));
  };

  return (
    !isAuth && (
      <div className="et-page-wrap py-5">
        <div className="row m-0">
          <div className="col-md-5 mx-auto text-center">
            <img
              src={common.loadImg("pickauni-logo-white.png")}
              alt="logo"
              className="pb-4"
              width={160}
            />
            <p className="mb-0">Admin Login</p>
          </div>
        </div>
        <div className="row m-0">
          <div className="col-md-5 mx-auto pt-4">
            <div className="box-header">
              <p className="title">Enter your admin credentials</p>
            </div>
            <div className="box-body">
              <div>
                <label className="form-label">Email Address</label>
                <div className="et-form-row">
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    maxLength={40}
                    value={fields.email}
                    onChange={(e) => {
                      resetError("email");
                      handleChange(e);
                    }}
                  />
                  <div className="email-fieldcheckbox checkbox-design">
                    <div className={"input-tick-active"}></div>
                  </div>
                  <p className="error-txt">
                    {error.email ? error.email : errors.email}
                  </p>
                </div>
                <label className="form-label mt-3">Password</label>
                <div className="et-form-row">
                  <input
                    type={view ? "text" : "password"}
                    name="password"
                    className="form-control"
                    maxLength={15}
                    onChange={(e) => {
                      resetError("password");
                      handleChange(e);
                    }}
                    value={fields.password}
                  />
                  <div className=" email-fieldcheckbox checkbox-design">
                    <div className="input-tick-active"></div>
                  </div>
                  <div className="pwd-eye" onClick={() => setView(!view)}>
                    <img
                      src={common.loadImg(
                        view ? "password_show.svg" : "password_hide.svg"
                      )}
                      alt="show"
                      className="et-cp"
                    />
                  </div>
                  <p className="error-txt">
                    {error.password ? error.password : errors.password}
                  </p>
                </div>
                <div className="d-flex justify-content-between mt-3">
                  {/* <div className="d-flex">
                    <div className="checkbox-design d-flex me-3">
                      <input
                        id="rememberLogin"
                        className="me-3"
                        type="checkbox"
                        name="rememberme"
                      />
                      <label
                        className="rememberlogin-text et-cp"
                        htmlFor="rememberLogin"
                      >
                        Remember Me
                      </label>
                    </div>
                  </div> */}
                  <div>
                    <Link to="/password/forgot" className="rememberlogin-text">
                      Forgot Password
                    </Link>
                  </div>
                </div>
                <div className="d-flex justify-content-center mt-4">
                  <button
                    className="btn-default"
                    disabled={loading}
                    onClick={onSubmit}
                  >
                    {loading ? "Processing" : "Sign In"}
                  </button>
                </div>
              </div>
            </div>
            <div className="shadow-div"></div>
            <div className="greenshow-div"></div>
          </div>
        </div>
      </div>
    )
  );
}
export default SignIn;
