import CssBaseline from "@mui/material/CssBaseline";
import { Fragment, useEffect, useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
// includes
import { common, types } from "helpers";
import InnerHeader from "./innerHeader";

const InnerLayout = (props) => {
  // props
  const navigate = useNavigate();
  const pathname = common.getPath(useLocation().pathname);
  // state
  const [isAuth] = useState(common.checkAuth());
  const [open, setOpen] = useState(true);
  const handleDrawer = () => {
    setOpen(!open);
  };
  const [permission] = useState(common.getUserPermission());
  // effect
  useEffect(() => {
    if (!isAuth) {
      navigate("/login");
    }
  }, [isAuth]);

  // render
  return (
    isAuth && (
      <Fragment>
        <InnerHeader handleDrawer={handleDrawer} />
        <div className="admin-wrap">
          <CssBaseline />
          <aside
            className={!open ? "sidebar-nav-wrap toggle" : "sidebar-nav-wrap"}
          >
            <ul className="sb-nav">
              {types.menus.map(
                (i, k) =>
                  permission.includes(i.roleKey) && (
                    <li
                      key={k}
                      className={
                        pathname === i.menuKey ? "active" : "in-active"
                      }
                    >
                      <Link to={i.url}>
                        <img src={common.loadImg(i.icon)} alt={i.name} />{" "}
                        <span>{i.name}</span>
                      </Link>
                    </li>
                  )
              )}
            </ul>
          </aside>
          <div
            className={
              !open
                ? "admin-content-wrap pl-toggle"
                : "admin-content-wrap pl-max"
            }
          >
            <Outlet {...props} />
          </div>
        </div>
      </Fragment>
    )
  );
};

export default InnerLayout;
