import { React, useState, useEffect, Fragment, useRef } from "react";
import {
  Link,
  useNavigate,
  useParams,
  useOutletContext,
} from "react-router-dom";
//includes
import { common, api, types } from "helpers";
import StatusBar from "elements/statusBar";
// elements
import ModalInfo from "elements/modalInfo";
// const
const htmlParse = require("html-react-parser");

function View() {
  // props
  let { id, idA } = useParams();
  const navigate = useNavigate();
  const refOverview = useRef(null);
  const refAdmission = useRef(null);
  const refModules = useRef(null);
  const refIntake = useRef(null);
  // state
  const [status, setStatus] = useState("process");
  const [result, setResult] = useState([]);
  const [courseId, setCourseId] = useState(null);
  const [activeTab, setActiveTab] = useState("A");
  const [tsList, setTsList] = useState([]);
  const [infoModal, setInfoModal] = useState(null);

  // effect
  useEffect(() => {
    getCourseInfo();
  }, []);

  // api
  const getCourseInfo = () => {
    let data = {
      url: "course",
      query: `/${id}`,
      method: "GET",
    };
    api.call(data, (res) => {
      let apiStatus = "error";
      if (res.status === 200) {
        setResult(res.data);
        apiStatus = "success";
      }
      setStatus(apiStatus);
    });
  };

  // support
  const shTestScore = (key) => {
    setTsList((prev) => {
      let nData = [...prev];
      if (nData.includes(key)) {
        nData.splice(nData.indexOf(key), 1);
      } else {
        nData.push(key);
      }
      return nData;
    });
  };

  //render
  return (
    <Fragment>
      {status !== "success" ? (
        <StatusBar status={status} />
      ) : (
        <Fragment>
          <div className="title-wrap d-flex align-items-center justify-content-between pt-0 pb-0">
            <div className="d-flex align-items-center">
              <img
                src={common.loadImg("back-icn.svg")}
                className="ed-cp pe-4"
                alt=""
                onClick={() => navigate(`/course/`)}
              />
              <Link
                to={`/university/view/${result.university._id}${
                  true === "agent" && idA ? `/${idA}` : ``
                }`}
                className="card-course-title-link"
              >
                <img
                  src={common.cloudImg(
                    result.university?.logo || "media/no_university_logo.png"
                  )}
                  height={80}
                  width={80}
                  alt=""
                  className="border-left-right et-cp p-2"
                />
              </Link>
              <div>
                <Link
                  to={`/university/view/${result.university._id}${
                    true === "agent" && idA ? `/${idA}` : ``
                  }`}
                  className="card-course-title-link"
                >
                  <p className="inner-page-title-sm ms-4 mb-0">
                    {result.university.name}
                  </p>
                </Link>
                <p className="inner-page-title ms-4 mb-0">{result.name}</p>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div>
                <span className="course-tag me-3">
                  {result.courseLevel || "Course level"}
                </span>
                <span className="course-tag bg-gold me-3">
                  {result.courseDurationType} Year
                </span>
                {result?.awarded && (
                  <span className="course-tag me-3">
                    {result.awarded} Awarded
                  </span>
                )}
              </div>
              <div className="d-flex justify-content-between align-items-center course-card-footer course-card-footer-pd">
                <div className="px-4">
                  <p className="label">Fee</p>
                  <p className="amount">
                    {result.tuitionFee
                      ? `${result.tuitionFee} ${result.currency}`
                      : "Not Added"}
                  </p>
                </div>
                <div className="px-4">
                  <a
                    className="apply-now"
                    onClick={() => {
                      if (!result.isPublished) {
                        common.notify(
                          "I",
                          "Please verify the details and click Save & Publish button"
                        );
                      }
                      navigate(`/course/edit/${id}`);
                    }}
                  >
                    {result.isPublished ? "Edit" : "Publish"}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="content-wrap" style={{ paddingTop: "95px" }}>
            <ul className="course-nav">
              <li className={activeTab === "A" ? "active" : ""}>
                <Link
                  to=""
                  onClick={() => {
                    common.scrollTo("top");
                    setActiveTab("A");
                  }}
                >
                  Overview
                </Link>
              </li>
              <li className={activeTab === "B" ? "active" : ""}>
                <Link
                  to=""
                  onClick={() => {
                    common.scrollToRef(refAdmission, 260);
                    setActiveTab("B");
                  }}
                >
                  Admission
                </Link>
              </li>
              <li className={activeTab === "C" ? "active" : ""}>
                <Link
                  to=""
                  onClick={() => {
                    common.scrollToRef(refModules, 260);
                    setActiveTab("C");
                  }}
                >
                  Modules
                </Link>
              </li>
              <li className={activeTab === "D" ? "active" : ""}>
                <Link
                  to=""
                  onClick={() => {
                    common.scrollTo("bottom");
                    setActiveTab("D");
                  }}
                >
                  Intake Dates
                </Link>
              </li>
            </ul>
            <div
              className="header-col mt-3"
              id="courseOverview"
              ref={refOverview}
            >
              <h3>Course Overview</h3>
            </div>
            <div className="content-col editor-content-wrap">
              {result.overview
                ? htmlParse(result.overview)
                : "Overview not added"}

              <h5 className="course-type mt-4">Course Types</h5>
              <div className="course-type-list d-flex">
                {result.attendanceType.length > 0 ? (
                  result.attendanceType.map((ctype, idx) => (
                    <span className="course-tag me-3" key={idx}>
                      {ctype}
                    </span>
                  ))
                ) : (
                  <span className="course-tag me-3">Course type not added</span>
                )}
              </div>

              <h5 className="course-type mt-4">Subjects</h5>
              <div className="course-type-list d-flex">
                {result.subject.length > 0 ? (
                  result.subject.map((sub, idx) => (
                    <span className="course-tag me-3" key={idx}>
                      {sub.name}
                    </span>
                  ))
                ) : (
                  <span className="course-tag me-3">Subjects not added</span>
                )}
              </div>
            </div>
            <div
              className="header-col"
              id="academicBackground"
              ref={refAdmission}
            >
              <h3>Admission requirements</h3>
            </div>
            <div className="content-col">
              <p className="filter-cards-title mb-3">Academic Background</p>
              <div className="row">
                <div className="col-md-6">
                  <div className="course-lc">
                    <p className="head-small">
                      Minimum Level of Education Completed
                    </p>
                    <p className="head-big">
                      {result?.educationLevel?.name || "Not added"}
                    </p>
                  </div>
                </div>
                {types.elItems.map(
                  (i, k) =>
                    result[i.value] && (
                      <div className="col-md-6" key={k}>
                        <div className="course-lc">
                          <p className="head-small">Minimum {i.label}</p>
                          <p className="head-big">
                            {i.value === "elGrade"
                              ? types.gradeValue[result[i.value]]
                              : result[i.value]}
                          </p>
                        </div>
                      </div>
                    )
                )}
              </div>
              <p className="filter-cards-title my-3">
                Minimum Language Test Scores
              </p>
              <div className="row">
                {result.exam.length > 0 ? (
                  result.exam.map((i, k) => (
                    <div className="col-md-6" key={k}>
                      <div
                        className="d-flex justify-content-between align-items-center course-inner-row head et-cp"
                        onClick={() => shTestScore(k)}
                      >
                        <p className="mb-0">{i.examType}</p>
                        <img
                          src={common.loadImg(
                            `${
                              tsList.includes(k) ? "up" : "down"
                            }-arrow-white.svg`
                          )}
                        />
                      </div>
                      <div
                        className={`course-inner-row body et-${
                          tsList.includes(k) ? "show" : "hide"
                        }`}
                      >
                        <p>{i.reading + i.writing + i.listing + i.speaking}</p>
                        <div className="d-flex align-items-center justify-content-between">
                          <div>
                            <label>Reading</label>
                            <p>{i.reading}</p>
                          </div>
                          <div>
                            <label>Writing</label>
                            <p>{i.writing}</p>
                          </div>
                          <div>
                            <label>Listening</label>
                            <p>{i.listing}</p>
                          </div>
                          <div>
                            <label>Speaking</label>
                            <p>{i.speaking}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <p>Minimum Language Test Scores not added</p>
                )}
              </div>
            </div>

            <div className="header-col" id="modules" ref={refModules}>
              <h3>Modules</h3>
            </div>
            <div className="content-col">
              <ul className="row">
                {result.modules.length > 0 ? (
                  result.modules.map((i, k) => (
                    <li className="col-md-4" key={k}>
                      <div className="course-modules-wrap course-modulesstep2">
                        <div className="module-header d-flex justify-content-between align-items-center">
                          <div>
                            <h3 className="module-title">{i.title}</h3>
                            <p className="module-p">{i.hourLecture}</p>
                            <p className="module-p">{i.sessions}</p>
                          </div>
                          <div className="checkbox-gray d-flex me-0">
                            <input
                              className="me-0"
                              type="checkbox"
                              defaultChecked={true}
                            />
                          </div>
                        </div>
                        <div className="content">
                          <p
                            className="module-content-p et-cp"
                            onClick={() =>
                              setInfoModal({
                                title: i.title,
                                message: i.description,
                              })
                            }
                          >
                            {common.trimString(i.description, 100)}
                          </p>
                        </div>
                      </div>
                    </li>
                  ))
                ) : (
                  <p>Modules not added</p>
                )}
              </ul>
            </div>
            <div className="header-col" id="intakeDates" ref={refIntake}>
              <h3>Course Intakes</h3>
            </div>
            <div className="content-col">
              <div className="d-flex flex-wrap">
                {result.intake.length > 0 ? (
                  result.intake.map((i) =>
                    i.month.map((o, p) => (
                      <div className="et-course-intakes me-3 mb-3" key={p}>
                        {common.getMonthList()[o - 1].label} {i.year}
                      </div>
                    ))
                  )
                ) : (
                  <p>Intake dates not added</p>
                )}
              </div>
            </div>
            <div className="course-footer">
              <div className="d-flex justify-content-between align-items-center course-bottom-wrap">
                <div className="course-bottom-wrap1 course-bottom-step2">
                  <p className="label mb-0">Fee</p>
                  <p className="amount mb-0">
                    {result.tuitionFee
                      ? `${result.tuitionFee} ${result.currency}`
                      : "Not Added"}
                  </p>
                </div>
                <div className="course-bottom-wrap2">
                  <p className="course-module-header-p mb-0">
                    {common.trimString(result.feeDetail, 100)}
                  </p>
                </div>
                <div className="course-bottom-wrap3">
                  <a
                    className="apply-now"
                    onClick={() => {
                      if (!result.isPublished) {
                        common.notify(
                          "I",
                          "Please verify the details and click Save & Publish button"
                        );
                      }
                      navigate(`/course/edit/${id}`);
                    }}
                  >
                    {result.isPublished ? "Edit" : "Publish"}
                  </a>
                </div>
              </div>
            </div>
            <div className="shadow-div1"></div>
            <div className="shadow-div2"></div>
          </div>
        </Fragment>
      )}
      {infoModal !== null && (
        <ModalInfo data={infoModal} onYes={() => setInfoModal(null)} />
      )}
    </Fragment>
  );
}
export default View;
