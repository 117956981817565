export const types = {
  // basic
  status: [
    { label: "Active", value: true },
    { label: "Inactive", value: false },
  ],
  register: [
    { label: "By Register", value: true },
    { label: "Agent student", value: false },
  ],
  published: [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ],
  verified: [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ],
  promotor: [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ],
  booleanYesNo: [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ],
  appPaymentStatus: [
    { label: "Paid", value: "paid" },
    { label: "Not Paid", value: "not_paid" },
  ],
  appPaymentProcess: [
    { label: "Online Payment", value: "online_payment" },
    { label: "With PromoCode", value: "with_promo" },
    { label: "Subscription", value: "subscription" },
  ],

  coursePaymentStatus: [
    { label: "Paid", value: "paid" },
    { label: "Not Paid", value: "not_paid" },
  ],
  coursePaymentProcess: [
    { label: "Online Payment", value: "online_payment" },
    { label: "With PromoCode", value: "with_promo" },
    { label: "Direct Payment", value: "direct" },
  ],
  registerFrom: [
    { label: "Web", value: "Web" },
    { label: "Mobile", value: "Mobile" },
    { label: "Agent", value: "Agent" },
    { label: "Admin", value: "Admin" },
  ],
  gender: [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
    { label: "Others", value: "Others" },
  ],
  marital: [
    { label: "Single", value: "Single" },
    { label: "Married", value: "Married" },
  ],
  // university & course
  courseLevel: ["Bachelor", "Master", "Foundation", "Doctorate"],
  courseType: ["Fulltime", "Distance", "Online", "Blended"],
  courseLength: [1, 2, 3, 4, 5],
  educationLevel: [
    { label: "Grade 12 / High School", value: "highschool" },
    { label: "2-Year Undergraduate Diploma", value: "diploma" },
    {
      label: "3-Year Undergraduate Advanced Diploma",
      value: "advanced_diploma",
    },
    { label: "3-Year Bachelors Degree", value: "3_bachelor" },
    { label: "4-Year Bachelor Degree", value: "bachelor" },
    { label: "Master Degree", value: "masters" },
    { label: "Doctoral", value: "doctoral" },
  ],
  gradeLevel: [100, 80, 60, 40, 30],
  gradeValue: { 100: "A", 80: "B", 60: "C", 40: "D", 30: "E" },
  testType: ["IELTS", "TOEFL", "IELTS Ukvi"],
  currency: ["USD", "GBP", "EUR", "CAD", "AUD"],
  commission: ["Percentage", "USD", "GBP", "EUR", "CAD", "AUD"],
  role: ["Agent", "Student", "Both"],
  elItems: [
    { label: "Percentage", value: "elPercentage" },
    { label: "Points", value: "elPoints" },
    { label: "Grade", value: "elGrade" },
    { label: "GPA", value: "elGpa" },
    { label: "CGPA", value: "elCgpa" },
  ],
  gradeScale: [
    { label: "4", value: "4" },
    { label: "5", value: "5" },
    { label: "10", value: "10" },
  ],
  promoCategory: [
    {
      label: "Course Fees",
      value: "course",
    },
    {
      label: "Application Fees",
      value: "application",
    },
  ],
  promoType: [
    {
      label: "Percentage",
      value: "percentage",
    },
    {
      label: "USD",
      value: "USD",
    },
  ],
  type: [
    { label: "All", value: "" },
    { label: "Partnered", value: "partnered" },
    { label: "Non Partnered", value: "nonpartnered" },
    { label: "Private", value: "private" },
    { label: "Public", value: "public" },
  ],
  UniversityType: [
    { label: "Partnered", value: "partnered" },
    { label: "Non Partnered", value: "nonpartnered" },
    { label: "Private", value: "private" },
    { label: "Public", value: "public" },
  ],
  paymentCurrency: [{ label: "USD", value: "USD" }],
  studentArrayFields: [
    "college",
    "exam",
    "workExp",
    "extraCurricular",
    "otherActivity",
  ],
  // page
  menus: [
    {
      name: "Home",
      icon: "home-icn.svg",
      url: "/home",
      menuKey: "home",
      roleKey: "hor",
    },
    {
      name: "Agent",
      icon: "students-icn.svg",
      url: "/agent",
      menuKey: "agent",
      roleKey: "agr",
    },
    {
      name: "Student",
      icon: "students-icn.svg",
      url: "/student",
      menuKey: "student",
      roleKey: "str",
    },
    {
      name: "Member",
      icon: "students-icn.svg",
      url: "/member",
      menuKey: "members",
      roleKey: "mer",
    },
    {
      name: "Applications",
      icon: "applications-icn.svg",
      url: "/application",
      menuKey: "application",
      roleKey: "apr",
    },
    {
      name: "Subjects",
      icon: "subjects-icn.svg",
      url: "/subject",
      menuKey: "subject",
      roleKey: "sur",
    },
    {
      name: "University",
      icon: "university-icn.svg",
      url: "/university",
      menuKey: "university",
      roleKey: "unr",
    },
    {
      name: "Courses",
      icon: "courses-icn.svg",
      url: "/course",
      menuKey: "course",
      roleKey: "cor",
    },
    {
      name: "Promo Code",
      icon: "setup-icn.svg",
      url: "/promo",
      menuKey: "promo",
      roleKey: "pcr",
    },
    {
      name: "Users",
      icon: "students-icn.svg",
      url: "/users",
      menuKey: "users",
      roleKey: "usr",
    },
    {
      name: "Setup",
      icon: "setup-icn.svg",
      url: "/setup",
      menuKey: "setup",
      roleKey: "ser",
    },
    {
      name: "Permissions",
      icon: "courses-icn.svg",
      url: "/permissions",
      menuKey: "permissions",
      roleKey: "per",
    },
  ],
  dashboard: [
    {
      name: "Agent",
      icon: "",
      url: "",
      key: "agent",
    },
    {
      name: "Student",
      icon: "",
      url: "",
      key: "student",
    },
    {
      name: "Course",
      icon: "",
      url: "",
      key: "course",
    },
    {
      name: "University",
      icon: "",
      url: "",
      key: "university",
    },
    {
      name: "Subject",
      icon: "",
      url: "",
      key: "subjects",
    },
    {
      name: "Promo Code",
      icon: "",
      url: "",
      key: "promoCode",
    },
    {
      name: "Application",
      icon: "",
      url: "",
      key: "application",
    },
    {
      name: "User",
      icon: "",
      url: "",
      key: "user",
    },
  ],
  permission: {
    label: {
      c: "Create",
      u: "Update",
      r: "Read",
      d: "Delete",
      ho: "Home",
      ag: "Agent",
      st: "Student",
      me: "Member",
      ap: "Applications",
      su: "Subjects",
      un: "University",
      co: "Course",
      pc: "Promo Code",
      us: "Users",
      se: "Setup",
      pe: "Permissions",
    },
    list: [
      {
        key: "ho",
        types: ["r"],
      },
      {
        key: "ag",
        types: ["r", "c", "u"],
      },
      {
        key: "st",
        types: ["r", "c", "u"],
      },
      {
        key: "me",
        types: ["r", "c", "u"],
      },
      {
        key: "ap",
        types: ["r"],
      },
      {
        key: "su",
        types: ["c", "u", "r"],
      },
      {
        key: "un",
        types: ["c", "u", "r", "d"],
      },
      {
        key: "co",
        types: ["c", "u", "r", "d"],
      },
      {
        key: "pc",
        types: ["c", "u", "r"],
      },
      {
        key: "us",
        types: ["c", "u", "r", "d"],
      },
      {
        key: "se",
        types: ["u", "r", "d"],
      },
      {
        key: "pe",
        types: ["c", "u", "r", "d"],
      },
    ],
  },

  studentView: [
    {
      key: "personal",
      title: "Personal",
      header: "General Information",
      headerLink: false,
      subHeader: true,
      description: {
        a: "Please enter your personal details",
        b: "Name, DOB, Gender, Nationality, Language,..etc",
        c: "",
      },
      multi: false,
      step: 1,
    },
    {
      key: "address",
      title: "Address Details",
      header: "",
      headerLink: false,
      subHeader: true,
      description: {
        a: "Please enter your address details",
        b: "Address, City, Country, Province, Postcode,…etc",
        c: "",
      },
      multi: false,
      step: 0,
    },
    {
      key: "education",
      title: "General",
      header: "Educational  Information",
      headerLink: false,
      subHeader: true,
      description: {
        a: "Please enter your education details",
        b: "Country of education, Highest level of education,…etc",
        c: "",
      },
      multi: false,
      step: 2,
    },
    {
      key: "college",
      title: "College",
      header: "",
      headerLink: false,
      subHeader: true,
      description: {
        a: "Please enter your college details",
        b: "Country of Institution, Level of education, Graduation date, Degree,…etc",
        c: "College details not added yet",
      },
      multi: true,
      step: 0,
    },
    {
      key: "exam",
      title: "Course",
      header: "Test Scores",
      headerLink: true,
      subHeader: false,
      description: {
        a: "Please enter your test scores details",
        b: "Test name, Score, Date, Document,…etc",
        c: "Test score details not added yet",
      },
      multi: true,
      step: 3,
    },
    {
      key: "background",
      title: "Background Informations",
      header: "Background Informations",
      headerLink: true,
      subHeader: false,
      description: {
        a: "Please enter your background details",
        b: "Visa process informations",
        c: "Background details not added yet",
      },
      step: 3,
    },
    {
      key: "workExp",
      title: "Company",
      header: "Work History",
      headerLink: true,
      subHeader: false,
      description: {
        a: "Please enter your work details ",
        b: "Company, Designation, Joining, end date and,…etc",
        c: "Company details not added yet",
      },
      multi: true,
      step: 4,
    },
  ],

  applicationSelect: [
    { value: "payment_pending:", label: "Pending Payment" },
    { value: "fees_payment:", label: "Fees Paid" },
    { value: "review:", label: "Under Review" },
    { value: "review_missing_info", label: "Missing Info" },
    { value: "submitted_to_university", label: "Submitted To University" },
    { value: "conditional_offer", label: "Offer" },
    { value: "course_payment", label: "Pending Payment" },
    { value: "course_payment_docs", label: "Proof of funds" },
    { value: "course_payment_interview", label: "Payment Interview" },
    { value: "accepted", label: "Accepted" },
    { value: "visa", label: "Visa Processing" },
    { value: "enrolled", label: "Enrolled" },
    { value: "rejected", label: "Rejected" },
    { value: "cancelled", label: "Cancelled" },
  ],

  applicationStatus: {
    payment_pending: "Pending Payment",
    fees_payment: "Fees Paid",
    review: "Under Review",
    review_missing_info: "Under Review",
    submitted_to_university: "Submitted To University",
    conditional_offer: "Offer",
    conditional_offer_letter: "Offer",
    conditional_offer_missing_info: "Offer",
    conditional_offer_interview: "Offer",
    unconditional_offer: "Offer",
    course_payment: "Pending Payment",
    course_payment_docs: "Proof of fund",
    course_payment_interview: "Payment Interview",
    accepted: "Accepted",
    visa: "Visa Processing",
    enrolled: "Enrolled",
    rejected: "Rejected",
    cancelled: "Cancelled",
  },

  filterFields: {
    agentSingle: {
      type: "object",
      value: "",
      finder: "",
    },
    agentMulti: {
      type: "array",
      value: [],
      finder: "",
    },
    promotorMulti: {
      type: "array",
      value: [],
      finder: "",
    },
    agentNameMulti: {
      type: "array",
      value: [],
      finder: "",
    },
    studentSingle: {
      type: "object",
      value: "",
      finder: "",
    },
    studentMulti: {
      type: "array",
      value: [],
      finder: "",
    },
    subAgentMulti: {
      type: "array",
      value: [],
      finder: "",
    },
    studentNameMulti: {
      type: "array",
      value: [],
      finder: "",
    },
    courseSingle: {
      type: "object",
      value: "",
      finder: "",
    },
    courseMulti: {
      type: "array",
      value: [],
      finder: "",
    },
    universitySingle: {
      type: "object",
      value: "",
      finder: "",
    },
    universityMulti: {
      type: "array",
      value: [],
      finder: "",
    },
    planMulti: {
      type: "object",
      value: "",
      finder: "",
    },
    country: {
      type: "object",
      value: "",
      finder: "",
    },
    province: {
      type: "object",
      value: "",
      finder: "",
    },
    city: {
      type: "object",
      value: "",
      finder: "",
    },
    applicationSelect: {
      type: "object",
      value: "",
      finder: "applicationSelect",
    },
    type: {
      type: "object",
      value: "",
      finder: "type",
    },
    published: { type: "boolObject", value: "", finder: "published" },
    status: { type: "boolObject", value: "", finder: "status" },
    register: { type: "boolObject", value: "", finder: "register" },
    verified: { type: "boolObject", value: "", finder: "published" },
    promotor: { type: "boolObject", value: "", finder: "promotor" },
    registerFrom: { type: "array", value: "", finder: "registerFrom" },
    profileSlider: { type: "twoArray", value: [0, 100] },

    appPaymentStatus: {
      type: "boolObject",
      value: "",
      finder: "appPaymentStatus",
    },
    appPaymentProcess: {
      type: "boolObject",
      value: "",
      finder: "appPaymentProcess",
    },
    coursePaymentStatus: {
      type: "boolObject",
      value: "",
      finder: "coursePaymentStatus",
    },
    coursePaymentProcess: {
      type: "boolObject",
      value: "",
      finder: "coursePaymentProcess",
    },

    feesSlider: { type: "twoArray", value: [1, 10000000] },
    dateA: { type: "date", value: "", finder: "" },
    dateB: { type: "date", value: "", finder: "" },
    dateC: { type: "date", value: "", finder: "" },
    dateD: { type: "date", value: "", finder: "" },
  },
  educationLevelProfile: [
    { label: "Grade 10 / O Levels", value: "Grade 10 / O Levels" },
    { label: "Grade 12 / High School", value: "Grade 12 / High School" },
    {
      label: "2-Year Undergraduate Diploma",
      value: "2-Year Undergraduate Diploma",
    },
    {
      label: "3-Year Undergraduate Advanced Diploma",
      value: "3-Year Undergraduate Advanced Diploma",
    },
    { label: "3-Year Bachelors Degree", value: "3-Year Bachelors Degree" },
    { label: "4-Year Bachelors Degree", value: "4-Year Bachelors Degree" },
    { label: "Master", value: "Master" },
    { label: "Doctoral Degree", value: "Doctoral Degree" },
  ],
  agentProfile: [
    {
      type: "personal_address",
    },
    {
      type: "education_college",
    },
    {
      type: "background",
    },
    {
      type: "exam",
    },
    {
      type: "workExp",
    },
    // {
    //   type: "document",
    // },
  ],
  financeYearTakenBefore: [
    { label: "1 Year", value: "1 Year" },
    { label: "2 Year", value: "2 Year" },
    { label: "3 Year", value: "3 Year" },
    { label: "4 Year", value: "4 Year" },
  ],
  visaStatus: [
    { label: "British National", value: "British National" },
    { label: "EU National", value: "EU National" },
    {
      label: "Indefinite Leave to Remain (ILR)",
      value: "Indefinite Leave to Remain (ILR)",
    },
    {
      label: "Limited Leave to Remain (LLR)",
      value: "Limited Leave to Remain (LLR)",
    },
    {
      label: "Family Member of a British National",
      value: "Family Member of a British National",
    },
    {
      label: "Family Member of an EU/ EAA National",
      value: "Family Member of an EU/ EAA National",
    },
    { label: "Refugee/ Asylum Seeker", value: "Refugee/ Asylum Seeker" },
  ],
  livingPeriod: [
    { label: "Less than a  Year", value: "Less than a  Year" },
    { label: "1 - 2 Years", value: "1 - 2 Years" },
    { label: "2 - 3 Years", value: "2 - 3 Years" },
    { label: "3 - 4 Years", value: "3 - 4 Years" },
    { label: "4 - 5 Years", value: "4 - 5 Years" },
    { label: "5 - 10 Years", value: "5 - 10 Years" },
    { label: "10 - 15 Years", value: "10 - 15 Years" },
    { label: "15 - 20 Years", value: "15 - 20 Years" },
    { label: "20 - 25 Years", value: "20 - 25 Years" },
    { label: "More than 30 Years", value: "More than 30 Years" },
  ],
  yearOfExp: [
    { label: "Less than 2 Years", value: "Less than 2 Years" },
    { label: "Less than 5 Years", value: "Less than 5 Years" },
    { label: "More than 5 Years", value: "More than 5 Years" },
  ],
  gradingSystem: [
    { label: "Percentage", value: "Percentage" },
    { label: "Points", value: "Points" },
    { label: "GPA", value: "elGpa" },
    { label: "CGPA", value: "elCgpa" },
    { label: "Letter", value: "Letter" },
  ],
  test: [
    { label: "IELTS", value: "IELTS" },
    { label: "IELTS UKVI", value: "IELTS UKVI" },
    { label: "TOEFL", value: "TOEFL" },
  ],
  passportCountry: [
    "AM",
    "AR",
    "AT",
    "AU",
    "BE",
    "BG",
    "BY",
    "BR",
    "CA",
    "CH",
    "CN",
    "CY",
    "CZ",
    "DE",
    "DK",
    "DZ",
    "EE",
    "ES",
    "FI",
    "FR",
    "GB",
    "GR",
    "HR",
    "HU",
    "IE",
    "IN",
    "IR",
    "ID",
    "IS",
    "IT",
    "JP",
    "KR",
    "LT",
    "LU",
    "LV",
    "LY",
    "MT",
    "MY",
    "MZ",
    "NL",
    "PL",
    "PT",
    "RO",
    "RU",
    "SE",
    "SL",
    "SK",
    "TR",
    "UA",
    "US",
  ],
};
